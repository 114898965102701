import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  approveRetailerController,
  approveUserRequestController,
  ownershipTypeController,
  rejectRetailerController,
  retailerDetailController,
} from "../../services/retailerServices/retailerServices";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import { dateFormatYear, showDateFormatYear } from "../../utility/utils";

function ViewRetailerRequestDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [retailerDetail, setRetailerDetail] = useState({});
  const [errors, setErrors] = useState("");
  const [reason, setReason] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [ownershipList, setOwnershipList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [showImageModal, setShowImageModal] = useState(false);
  const [image, setImage] = useState("");
  const [warningModal, setWarningModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useButtonLoader(
    "Submit",
    "Please wait..."
  );
  console.log(id);

  const fetchRetailerDetail = async () => {
    try {
      const data = await retailerDetailController(id);
      if (data?.code === 200) {
        setRetailerDetail(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Fetching Ownership Type
  const fetchingOwnershipType = async () => {
    try {
      const response = await ownershipTypeController();
      console.log(response, "ownerShipType");
      if (response?.code === 200) {
        setOwnershipList(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // API request Handler
  const apiRequestHandler = async (e) => {
    e.preventDefault();
    if (
      retailerDetail?.isAddressRequest === 1 &&
      retailerDetail?.isApproved === 1
    ) {
      await fetchingUpdateAddress();
      setShowApproveModal(false);
      navigate("/retailer-management");
    } else {
      if (retailerDetail?.isAddressRequest === 1) {
        await fetchingApproveRetailer();
        await fetchingUpdateAddress();
        setShowApproveModal(false);
        navigate("/retailer-management");
      } else {
        await fetchingApproveRetailer();
        setShowApproveModal(false);
        navigate("/retailer-management");
      }
    }
  };

  const fetchingApproveRetailer = async (e) => {
    try {
      const data = await approveRetailerController(id);
      if (data?.code === 200) {
        // toast.success(data?.message);
        // navigate("/retailer-management");
      } else {
        toast.error(data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Fetching API for Update Address
  const fetchingUpdateAddress = async (e) => {
    try {
      const data = await approveUserRequestController(id);
      if (data?.data?.code === 200) {
        toast.success(data?.message);
        navigate("/retailer-management");
      } else {
        toast.error(data?.data?.message || data?.response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchingeRejectRetailer = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    if (!reason) setErrors("Reason is mandatory");
    if (reason) {
      try {
        const data = await rejectRetailerController(reason);
        setButtonLoader(false);
        if (data?.code === 200) {
          toast.success(data?.message);
          navigate("/retailer-management");
        } else {
          toast.error(data?.message);
        }
      } catch (e) {
        console.log(e.message);
        setButtonLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchRetailerDetail();
    fetchingOwnershipType();
  }, []);

  console.log("retailer",retailerDetail)
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Retailer Detail
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Personal Details</h4>
                  <form className="form-sample">
                    {/* <p className="card-description"> Personal info </p> */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Retailer Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={retailerDetail.retailerName}
                              // onChange={inputFieldHandler}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Aadhar Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={12}
                              className="form-control"
                              name="aadharNumber"
                              value={retailerDetail.aadharNumber}
                              // onChange={inputFieldHandler}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Email Address
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              value={retailerDetail.email}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Phone Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={10}
                              className="form-control"
                              name="phoneNumber"
                              value={retailerDetail.mobileNumber}
                              // onChange={inputFieldHandler}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Secondary Phone Number
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxlength="10"
                              className="form-control"
                              name="secondaryMobileNumber"
                              placeholder="Enter Secondary Phone Number"
                              disabled
                              value={retailerDetail?.secondaryMobileNumber}
                              // onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 className="card-title">Business Details</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Store Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="shopName"
                              value={retailerDetail?.BussinessDetail?.shopName}
                              // onChange={inputFieldHandler}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Shop Location
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="shopLocation"
                              value={retailerDetail.shopLocation}
                              // onChange={inputFieldHandler}
                              disabled
                            />
                          </div>
                        </div>
                      </div> */}
                       <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                             Address Line 1
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="address1"
                                value={retailerDetail?.BussinessDetail?.address1 || ""}
                                disabled
                              />
            
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                             Address Line 2
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="address2"
                                value={retailerDetail?.BussinessDetail?.address2 || ""}
                                disabled
                              />
                              
                            </div>
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                             City
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={retailerDetail?.BussinessDetail?.city || ""}
                                disabled
                              />
                            
                            </div>
                          </div>
                          <div className="col-md-6">
                            
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                             State
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                value={retailerDetail?.BussinessDetail?.state || ""}
                                disabled
                              />
                              
                            </div>
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                               pinCode
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="pincode"
                                value={retailerDetail?.BussinessDetail?.pincode || ""}
                                disabled
                              />
                            </div>
                          </div>
                          </div>
                    </div>

                    <div className="row">
                      {retailerDetail.newShopLocation && (
                        <>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                            New Address Line 1
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="NewShopLocation"
                                value={retailerDetail?.newShopLocation?.address1 || ""}
                                disabled
                              />
            
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                            New Address Line 2
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="newShopLocation"
                                value={retailerDetail?.newShopLocation?.address2 || ""}
                                disabled
                              />
                              
                            </div>
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                            New City
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="newShopLocation"
                                value={retailerDetail?.newShopLocation?.city || ""}
                                disabled
                              />
                            
                            </div>
                          </div>
                          <div className="col-md-6">
                            
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                            New State
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="newShopLocation"
                                value={retailerDetail?.newShopLocation?.state || ""}
                                disabled
                              />
                              
                            </div>
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              New pinCode
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="newShopLocation"
                                
                                value={
                                  retailerDetail.newShopLocation?.pincode }
                                disabled
                              />
                              <span style={{ color: "red" }} className="error">
                                {
                                  "The retailer has updated Address*."
                                }
                              </span>
                            </div>
                          </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Ownership Type
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-control"
                              disabled
                              name="ownershipType"
                              value={retailerDetail?.BussinessDetail?.type}
                            >
                              {retailerDetail?.BussinessDetail?.type ? (
                                ownershipList.map(({ id, types }) => (
                                  <option key={id} value={id}>
                                    {types}
                                  </option>
                                ))
                              ) : (
                                <option>Select Ownership Type</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            GST Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              name="gstNumber"
                              value={retailerDetail?.BussinessDetail?.gstNumber}
                              // onChange={inputFieldHandler}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            PAN Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="panName"
                              value={retailerDetail?.BussinessDetail?.panName}
                              // onChange={inputFieldHandler}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            PAN Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              name="panBussiness"
                              value={
                                retailerDetail?.BussinessDetail?.panBussiness
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {retailerDetail?.BussinessDetail?.panImage ? (
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              PAN Image
                            </label>

                            <div className="col-sm-9">
                              <div className="mb-4 cstm_img_set_retails">
                                <img
                                  src={
                                    retailerDetail?.BussinessDetail?.panImage
                                  }
                                  className="mb-2 mw-100 w-100 rounded"
                                  alt="pan"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        {retailerDetail?.BussinessDetail?.shopImage ? (
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Shop Image
                            </label>

                            <div className="col-sm-9">
                              <div className="mb-4 cstm_img_set_retails">
                                <img
                                  src={
                                    retailerDetail?.BussinessDetail?.shopImage
                                  }
                                  className="mb-2 mw-100 w-100 rounded"
                                  alt="pan"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {retailerDetail?.LicenceDetails &&
                      retailerDetail?.LicenceDetails.length > 0 && (
                        <React.Fragment>
                          <h4 className="card-title">Licence Details</h4>
                          <div className="table-responsive mt-4">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Licence Name</th>
                                  <th>Licence Number</th>
                                  <th>Expiry Date</th>
                                  <th>Licence Image</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  // retailerDetail?.LicenceDetails &&
                                  // retailerDetail?.LicenceDetails.length > 0 ?
                                  retailerDetail?.LicenceDetails.map(
                                    (
                                      {
                                        licenceNumber,
                                        expiryDate,
                                        userId,
                                        id,
                                        storeImage,
                                        approvedLicenceName,
                                      },
                                      index
                                    ) => (
                                      <React.Fragment key={id}>
                                        <tr>
                                          <td>{++index}</td>
                                          <td>{approvedLicenceName}</td>
                                          <td>{licenceNumber}</td>
                                          <td>
                                            {showDateFormatYear(expiryDate)}
                                          </td>
                                          <td>
                                            <div className="actioretailerDetail?.LicenceDetail?.n_button_wrap">
                                              <div className="switch_button"></div>
                                              <Link
                                                onClick={() => {
                                                  setShowImageModal(true);
                                                  setImage(storeImage);
                                                }}
                                                to="#"
                                                className="btn btn-outline-primary cstm_btn_wrap_theme me-2"
                                              >
                                                View Image
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    )
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                        </React.Fragment>
                      )}

                    {/* { retailerDetail?.isProfileCompleted===3 || retailerDetail?.isProfileCompleted===0  ? ( */}
                    <div className="mt-4">
                      <button
                        type="submit"
                        // disabled={retailerDetail.isApproved === 0}
                        className="btn btn-gradient-primary mb-2 mr-2 mx-2"
                        onClick={(e) => {
                          if (
                            // retailerDetail?.isProfileCompleted === 1 ||
                            // retailerDetail?.isProfileCompleted === 0 ||
                            // (retailerDetail?.isProfileCompleted === 1 &&
                            //   retailerDetail?.isAddressRequest === 1)

                            retailerDetail?.isProfileCompleted === 0
                          ) {
                            e.preventDefault();
                            toast.error("This retailer has incomplete profile");
                          } else {
                            setShowApproveModal(true);
                            e.preventDefault();
                          }
                        }}
                      >
                        Approve
                      </button>
                      <button
                        type="submit"
                        // disabled={retailerDetail.isApproved === 0}
                        className="btn btn-gradient-danger mb-2"
                        onClick={(e) => {
                          if (
                            retailerDetail?.isProfileCompleted === 1 ||
                            retailerDetail?.isProfileCompleted === 0 ||
                            (retailerDetail?.isProfileCompleted === 1 &&
                              retailerDetail?.isAddressRequest === 1)
                          ) {
                            toast.error("This retailer has incomplete profile");
                            e.preventDefault();
                          } else {
                            setShowModal(true);
                            e.preventDefault();
                          }
                        }}
                      >
                        Cancel (with reason)
                      </button>
                    </div>
                    {/* ) : null} */}
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Reject Retailer Modal */}
          <ModalBox
            status={showModal}
            onClose={() => {
              setShowModal(false);
              setErrors("");
            }}
            title={"Disapprove Retailer"}
            customizedFooter={<></>}
          >
            <React.Fragment>
              <div className="card">
                <div className="card-body">
                  <form className="forms-sample">
                    <div className="form-group">
                      <label htmlFor="Banner ">
                        Reason for Disapproval{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="exampleInputName1"
                        placeholder="Write here..."
                        onChange={(e) =>
                          setReason({
                            ...reason,
                            reason: e.target.value,
                            id: id,
                          })
                        }
                      />
                      <span style={{ color: "red" }} className="error">
                        {errors}
                      </span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        ref={buttonLoader}
                        className="btn btn-gradient-primary me-2 mx-2"
                        onClick={(e) => {
                          fetchingeRejectRetailer(e);
                        }}
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-light"
                        onClick={(e) => {
                          setShowModal(false);
                          e.preventDefault();
                          setErrors("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </React.Fragment>
          </ModalBox>

          {/* Accept Retailer Modal */}
          <ModalBox
            status={showApproveModal}
            onClose={() => {
              setShowApproveModal(false);
            }}
            title="Accept Retailer"
            description="Are you sure you want to accept request of this Retailer?"
            action={
              // retailerDetail?.isAddressRequest === 1 &&
              // retailerDetail?.isApproved === 1
              //   ? fetchingUpdateAddress
              //   : fetchingApproveRetailer
              (e) => apiRequestHandler(e)
            }
          />

          {/* Show Image in Modal */}
          <ModalBox
            status={showImageModal}
            onClose={() => {
              setShowImageModal(false);
            }}
            title={"Licence Image"}
            customizedFooter={<></>}
          >
            <React.Fragment>
              <div className="card">
                <div className="card-body">
                  <form className="forms-sample">
                    {image ? (
                      <div className="form-group text-center">
                        <img src={image} height="400px" width="400px" />
                      </div>
                    ) : (
                      <div className="form-group text-center">
                        <h4>No Image Found</h4>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </React.Fragment>
          </ModalBox>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewRetailerRequestDetail;
