import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";
import { toast } from "react-toastify";

// Fetching Retailer List Api
export const retailerListController = async (
  activePage = 1,
  filterByProfile = null,
  filterByApprove = null,
  filterByStatus = null,
  search = "",
  filterByDate = "",
  limit
  // filterByDate = null
) => {
  try {
    const params = {};
    params.page = activePage;
    params.profileStatus = filterByProfile;
    params.isApprove = filterByApprove;
    params.status = filterByStatus;
    params.search = search;
    params.date = filterByDate ? filterByDate : "";
    params.limit = limit;
    // console.log(params, "params");
    const  data  = await instance.get(`admin/retailer-list`, {
      params,
      ...setJwtToken(),
    });
    if (data?.data?.code === 200) {
      return data?.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data?.data;
    }
  } catch (e) {
    toast.error("An error occurred during fetching retailer list.");
    return null;
  }
};

// Fetching Retailer Add Api
export const addRetailerController = async (reqData) => {
  const data = await instance.post(
    `admin/create-retailer`,
    reqData,
    multipartJwtToken()
  );
  if (data?.data?.code === 200) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data.data;
  }
};

// Fetching Retailer Detail Api
export const retailerDetailController = async (id) => {
  const data = await instance.get(
    `admin/retailer-detail?id=${id}`,
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data.data;
  }
};

// Fetching Retailer Status Change API
export const retailerStatusChangeController = async (id) => {
  // console.log(id);
  const data = await instance.post(
    `admin/update-retailer-status`,
    { id: id },
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data.data;
  }
};

// Fetching Request List API
export const requestListController = async (
  activePage,
  search,
  limit,
  filterByDate
) => {
  const params = {};
  params.page = activePage ? activePage : "";
  params.search = search ? search : "";
  params.limit = limit ? limit : "";
  params.date = filterByDate ? filterByDate : "";

  const data = await instance.get(`admin/retailer-requests-list`, {
    params,
    ...setJwtToken(),
  });
  if (data?.data?.code === 200) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data.data;
  }
};

// Fetching Retailer List Without Pagination API
export const retailerListWithoutPaginationController = async (
) => {
  try {
    const data = await instance.get(`admin/retailer-list-without-pagination`, {
      ...setJwtToken(),
    });

    if (data?.data?.code === 200) {
      return data?.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message);
      return data?.data;
    }
  } catch (e) {
    toast.error("An error occurred during fetching retailer list.");
    return null;
  }
};


//Fetching Request Detail API
export const requestDetailController = async () => {
  const data = await instance.get(
    `admin/retailer-requests-list`,
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data.data;
  }
};

export const editRetailerDetailsController = async (reqData) => {
  // console.log("API hitting");
  const data = await instance.post(
    `admin/update-retailer`,
    reqData,
    multipartJwtToken()
  );
  if (data?.data?.code === 200) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data.data;
  }
};

// Delete Scheme Controller
export const deleteRetailerController = async (reqData) => {
  const data = await instance.post(
    `admin/user-delete`,
     reqData ,
    setJwtToken()
  );
  return data;
};

//Fetching Approve Retailer APi
export const approveRetailerController = async (id) => {
  try {
    const { data } = await instance.post(
      `admin/approve-retailer`,
      { id: id },
      setJwtToken()
    );
    return data;
  } catch (e) {
    toast.error("An error occured during fetching request detail");
    return null;
  }
};

//Fetching Reject Retailer APi
export const rejectRetailerController = async (reqData) => {
  try {
    const { data } = await instance.post(
      `admin/reject-retailer`,
      { id: reqData.id, reason: reqData.reason },
      setJwtToken()
    );
    return data;
  } catch (e) {
    toast.error("An error occured during fetching request detail");
    return null;
  }
};

// Fetching OwnerShip Type Api
export const ownershipTypeController = async () => {
  try {
    const { data } = await instance.get(`admin/ownership-list`, setJwtToken());
    return data;
  } catch (e) {
    toast.error("An error occured during fetching OwnerShip");
    return null;
  }
};

// Fetching Orders List API
export const orderListController = async (id, status) => {
  try {
    const params = {};
    params.retailerId = id;
    params.status = status;
    const { data } = await instance.get(`admin/user-order-detail`, {
      params,
      ...setJwtToken(),
    });
    return data;
  } catch (e) {
    toast.error("An error occured during fetching Order List");
    return null;
  }
};

// Fetching License Forms API
export const licenseFormsController = async () => {
  const data = await instance.get(`api//licence-list`, setJwtToken());
  if (data?.data?.code === 200) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data.data;
  }
};

//Fetching Approve Retailer APi
export const approveUserRequestController = async (id) => {
  try {
    const data = await instance.post(
      `admin/approve-users-request
      `,
      { userId: id },
      setJwtToken()
    );
    return data;
  } catch (e) {
    toast.error(e.message);
    return null;
  }
};

// Fetching Delete Request List Api
export const deleteRequestListController = async (page,limit,search) => {
  const params={
    page:page,
    limit:limit,
    search:search
  }
  const data = await instance.get(`admin/user-delete-requests`,{params , ...setJwtToken()});
  return data;
};