import React, { useState, useEffect, useLayoutEffect } from "react";
import { renderToString } from "react-dom/server";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
import { useNavigate} from 'react-router-dom';
import {
  changeProductStatusController,
  productListController,
} from "../../services/productServices/productListingController";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import {
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
  timeFormat,
} from "../../utility/utils";
import ToggleStatus from "../../common/ToggleStatus";
import { Checkbox, FormControlLabel } from "@mui/material";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import TypingPlaceholder from "../../common/TypingPlaceholder";
import { shortBookListingController } from "../../services/digitalShortbookServices/shortbookController";
import { usePermission } from "../../context/create_Context";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";
// import logo from "../../assets/images/user.png"

const DigitalShortbook = () => {
  const [shortbookList, setShortbookList] = useState([]);
  const [viewDetail, setViewDetail] = useState({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [cartData, setCartData] = useState();
  const [image, setImage] = useState("");
  const [productId, setProductId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const { permission } = usePermission();
  const navigate = useNavigate();

  const fetchingShortbookList = async () => {
    try {
      show();
      const response = await shortBookListingController();
      console.log(response)
      // console.log(response?.data?.data?.data,"responseShortBook");
      if (response?.data?.code === 200) {
        hide();
        setTotalList(response?.data?.data?.count);
        setShortbookList(response?.data?.data?.data);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  // Clear filter
  const clearFilter = () => {
    setActivePage(1);
    setLimit(10);
    setSearch("");
    setFilterByDate("");
    // fetchingProductList();
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  const handleUpdateCart = () => {
    navigate(`/digital-shortbook/create-cart/${viewDetail.UserId}/${viewDetail.id}`, {
      state: { cartData: viewDetail.cartJson },
    });
  };

  // console.log(viewDetail.userId, "UserId--->");

  const shortbookDetail = (
    retailerName,
    createdAt,
    status,
    shopBookimage,
    UserId,
    id
  ) => {
    setShowModal(true);
    setViewDetail({
      ...viewDetail,
      retailerName: retailerName,
      status: status,
      createdAt: createdAt,
      shopBookimage: shopBookimage,
      UserId: UserId,
      id: id,
    });
  };

  useEffect(() => {
    fetchingShortbookList();
  }, []);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title">Digital Shortbook</h2>
              <div className="button_wrap"></div>
            </div>
          </div>
          <div className="row wrap_selectCstm">
            <div className="col-lg-3 cstm_padding_set"></div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>User Name</th>
                          <th>Uploaded Date & Time</th>
                          <th>Image Link</th>
                          <th>View Cart</th>
                          <th>Status</th>
                          {actionsChecker(permission, 12) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {shortbookList && shortbookList.length > 0 ? (
                          shortbookList.map(
                            (
                              {
                                UserDetail,
                                id,
                                userId,
                                status,
                                shopBookimage,
                                createdAt,
                                cartJson,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={++index}>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{UserDetail?.retailerName || "---"}</td>
                                    <td>{`${showDateFormatYear(
                                      createdAt
                                    )} || ${timeFormat(createdAt)}`}</td>
                                    <td>
                                      {/* <img
                                        src={
                                          shopBookimage
                                            ? shopBookimage
                                            : "../../assets/images/user.png"
                                        }
                                        alt=""
                                      /> */}
                                      <div className="action_button_wrap">
                                        <div className="switch_button"></div>
                                        <Link
                                          onClick={() => {
                                            setShowImageModal(true);
                                            setImage(shopBookimage);
                                          }}
                                          to="#"
                                          className="btn btn-outline-primary cstm_btn_wrap_theme me-2"
                                        >
                                          View Image
                                        </Link>
                                      </div>
                                    </td>
                                    <td>
                                      <Link
                                        onClick={() => {
                                          setShowCartModal(true);
                                          setCartData(JSON.parse(cartJson));
                                        }}
                                        to="#"
                                        className="btn btn-outline-primary cstm_btn_wrap_theme me-2"
                                      >
                                        View Cart
                                      </Link>
                                    </td>
                                    <td>
                                      {status === 1
                                        ? "Processing"
                                        : status === 2
                                        ? "Cart Ready"
                                        : status === 3
                                        ? "Cart Made"
                                        : ""
                                      }
                                    </td>
                                    {permissionChecker(permission, 12, 3) && (
                                      <td>
                                        <div className="action_button_wrap">
                                          <div className="switch_button"></div>
                                          <Link
                                            onClick={() => {
                                              shortbookDetail(
                                                UserDetail?.retailerName || "",
                                                createdAt || "",
                                                status || "",
                                                shopBookimage || "",
                                                userId,
                                                id
                                              );
                                            }}
                                            to="#"
                                            className="btn btn-outline-primary cstm_btn_wrap_theme me-2"
                                          >
                                            View
                                          </Link>
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={6}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Show Image Modal */}
          <ModalBox
            status={showImageModal}
            onClose={() => {
              setShowImageModal(false);
            }}
            title={""}
            customizedFooter={<></>}
            size={"lg"}
          >
            <React.Fragment>
              <div class="card">
                <div class="card-body">
                  <form class="forms-sample">
                    {image ? (
                      <div class="form-group text-center">
                        {image ? (
                          <img src={image} height="600px" width="600px" />
                        ) : (
                          "No Image Found"
                        )}
                      </div>
                    ) : (
                      <div className="form-group text-center">
                        <h4>No Image Found</h4>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </React.Fragment>
          </ModalBox>

          {/* Show Modal For Managing Products */}
          <ModalBox
            status={showModal}
            onClose={() => setShowModal(false)}
            title={""}
            customizedFooter={<></>}
            modalSize={"cstm_modal_size"}
            centered={true}
            size={"md"}
          >
            <div className="card cstm_card_user_data">
              <div className="card-body text-center">
                <div className="row">
                  <div className="col-12">
                    <p>
                      <b>User Name</b> : {viewDetail?.retailerName || "---"}
                    </p>
                  </div>
                  <div className="col-12">
                    <p>
                      <b>Uploaded On</b> :
                      {showDateFormatYear(viewDetail.createdAt) || "---"}
                    </p>
                  </div>
                  <div className="col-12">
                    <p>
                      <b>Status</b> :
                      {viewDetail.status === 1
                        ? "Processing"
                        : viewDetail.status === 2
                        ? "Cart Ready" :
                        viewDetail?.status ===3 
                        ? "Cart Made"
                        : "---"}
                    </p>
                  </div>
                  <div className="col-12">
                    <p>
                      <b>Image Link</b>
                      <img
                        src={
                          viewDetail.shopBookimage
                            ? viewDetail.shopBookimage
                            : "../../assets/images/user.png"
                        }
                        alt=""
                        width="50px"
                        height="50px"
                      />
                    </p>
                  </div>
                </div>
                {viewDetail.status === 1 && (
                  <Link
                    to={`/digital-shortbook/create-cart/${viewDetail.UserId}/${viewDetail.id}`}
                    className="btn btn-gradient-primary mt-4"
                  >
                    Create Cart
                  </Link>
                )}
                   {viewDetail.status === 2 && (
                  <Link
                  to={`/digital-shortbook/create-cart/${viewDetail.UserId}/${viewDetail.id}`}
                  state={{ cartData: JSON.parse(shortbookList.find(item => item.id === viewDetail.id).cartJson) }}
                  className="btn btn-gradient-primary mt-4"
                  
                >
                  Update Cart
                </Link>
                )}
              </div>
            </div>
          </ModalBox>

          {/* Show Cart Data */}
          <ModalBox
            status={showCartModal}
            onClose={() => setShowCartModal(false)}
            title={""}
            customizedFooter={<></>}
            modalSize={"cstm_modal_size"}
            centered={true}
            size={"md"}
          >
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData && cartData.length > 0 ? (
                        cartData.map(
                          ({ productId, qty, productName , bundleName }, index) => {
                            return (
                              <React.Fragment key={++index}>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{productName ? productName : bundleName ?  bundleName :  "---"}</td>
                                  <td>{qty || "---"}</td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td colSpan={3}>No Cart Product Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DigitalShortbook;

