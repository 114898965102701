import moment from "moment";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { ContentState, EditorState, convertFromHTML } from "draft-js";


export function isLoggedIn() {
  let session = getObject(process.env.REACT_APP_AUTH) || {};
  session = Object.keys(session).length && JSON.parse(session);
  let accessToken = session;
  return accessToken;
}

export function getObject(key) {
  if (window && window.localStorage) {
    return window.localStorage.getItem(key);
  }
}

export function getAdminToken() {
  const token = localStorage.getItem("token");

  if (token) {
    return token;
  } else {
    return null;
  }
}

export function getAdminGrokId() {
  const token = localStorage.getItem("adminGrokId");

  if (token) {
    return token;
  } else {
    return null;
  }
}

export function multiPartData(data) {
  let multiPart = new FormData();
  for (let key in data) {
    multiPart.append(key, data[key]);
  }

  return multiPart;
}

export function checkPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export function capitalizeFirstLetter(string) {
  //return ;
  return string;
  //return str.charAt(0).toUpperCase() + str.slice(1);
}

export function dateFormat(date) {
  if (date) {
    return new Date(Date.parse(date)).toLocaleString("default", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  } else {
    return "-- --- ----";
  }
  // return moment(date).format("DD/MM/YYYY");
}

export function dateFormatYear(date, month = "") {
  // const startDateUTC = new Date(date).toLocaleString("en-US", {
  //   timeZone: "UTC",
  // });
  // let res = moment(date).format("h:mm a");
  // console.log(res);
  if (month) {
    return moment(date).format("D MMMM YYYY");
  } else {
    return moment(date).format("YYYY-MM-DD");
  }
}


export function showDateFormatYear(date) {
  const startDateUTC = new Date(date).toLocaleString("en-US", {
    timeZone: "UTC",
  });
  if (date) {
    return moment(startDateUTC).format("DD-MM-YYYY");
  } else {
    return null
  }
}

export function subtractAndFormatDate(dateString, month = "") {
  if (!dateString) {
    return null;
  }
  if (month) {
    const originalDate = new Date(dateString);
    const adjustedDate = new Date(originalDate);
    adjustedDate.setHours(originalDate.getHours() - 5);
    adjustedDate.setMinutes(originalDate.getMinutes() - 30);
    const formattedDate = new Date(Date.parse(adjustedDate)).toLocaleString(
      "default",
      {
        day: "numeric",
        month: "short",
        year: "numeric",
      }
    );

    return formattedDate;
  }
  const originalDate = new Date(dateString);
  const adjustedDate = new Date(originalDate);

  // Subtract 5 hours and 30 minutes
  adjustedDate.setHours(originalDate.getHours() - 5);
  adjustedDate.setMinutes(originalDate.getMinutes() - 30);

  // Format as "yyyy-mm-dd"
  const formattedDate = adjustedDate.toISOString().split("T")[0];
  console.log(adjustedDate);

  return formattedDate;
}

export function timeFormat(t) {
  // const startDateUTC = new Date(t).toLocaleString("en-US", {
  //   timeZone: "UTC",
  // });
  // console.log(startDateUTC);
  let res = moment(t).format("h:mm a");
  return res;
}

export function fullTimeFormat(t) {
  // console.log(t, "t");
  let res = moment(t).format("HH:mm:ss");
  // console.log(res, "res");
  return res;
}

export function dateTimeFormat(date) {
  if(date){

    console.log(date,"--->Date");
    return moment(date).format("DD-MM-YYYY h:mm:ss A");
  }else{
    return null
  }
}

export function ShortString(string) {
  return string.substr(-100);
}

//get device status online ofline time
export function deviceOnlineStatus(deviceStatusTime) {
  let currentTime = moment(new Date());
  let lastUpdatedTime = moment(new Date(parseInt(deviceStatusTime)));
  let totalMinutes = currentTime.diff(lastUpdatedTime, "minutes");
  let deviceStatus;
  if (totalMinutes > 10) {
    deviceStatus = false;
  } else {
    deviceStatus = true;
  }
  return deviceStatus;
}

export function errorMsg() {
  return "Something wents wrong, please try again";
}

// export function validateEmail(mail) {
//   var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//   if (mail.match(mailFormat)) {
//     return true;
//   } else {
//     return false;
//   }
// }

export const timeConverter = (time) => {
  if (time) {
    const fullYear = new Date().getFullYear();
    const month = new Date().getMonth();
    const date = new Date().getDate();
    const editTime = time.split(":");
    return new Date(fullYear, month, date, editTime[0], editTime[1]);
  } else {
    return new Date();
  }
};

export const dateConvertToTime = (date) => {
  return moment(date).format("HH:mm");
};

export function groupBy(array, key) {
  const arr = array.reduce((acc, obj) => {
    const property = obj[key];
    acc[property] = acc[property] || [];
    acc[property].push(obj);
    return acc;
  }, {});
  let completeArr = [];
  for (let a in arr) {
    completeArr.push({ type: a, data: arr[a] });
  }
  return completeArr;
}


// Time Difference For Showing Time 
export function showFullTimeFormat(t) {
    const startDateUTC = new Date(t).toLocaleString("en-US", {
    timeZone: "UTC",
  });
  let res = moment(startDateUTC).format("HH:mm:ss");
  // console.log(res, "res");
  return res;
}
export function wrapImagesWithFigure(rowData) {
  // const parser = new DOMParser();
  // const doc = parser.parseFromString(rowData, "text/html");
  // const imgTags = doc.getElementsByTagName("img");

  // for (let i = 0; i < imgTags.length; i++) {
  //   const img = imgTags[i];
  //   const figure = doc.createElement("figure");
  //   const rowDiv = parser
  //     .parseFromString(
  //       '<div class="" data-block="true" data-editor="78gim" data-offset-key="dqi6k-0-0"><div data-offset-key="dqi6k-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span data-offset-key="dqi6k-0-0"><br data-text="true"></span></div></div>',
  //       "text/html"
  //     )
  //     .querySelector("div");

  //   img.parentNode.insertBefore(figure, img);
  //   figure.appendChild(img);
  //   figure.insertAdjacentElement("afterend", rowDiv);
  // }

  // return doc.documentElement.innerHTML;
  return rowData;
}

export const fixedImageDimension = (data) => {
  var tempElement = document.createElement("div");
  tempElement.innerHTML = data;

  var images = tempElement.querySelectorAll("img");

  for (var i = 0; i < images.length; i++) {
    if (images[i].style.height === "" || images[i].style.width === "") {
      images[i].style.height = "250px";
      images[i].style.width = "250px";
    }
  }

  return tempElement.innerHTML;
};

export const rawToHtmlConverter = (data) => {
  return fixedImageDimension(draftToHtml(convertToRaw(data)));
};


// this is for html to raw
export function htmlToRaw(data) {
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(wrapImagesWithFigure(data))
    )
  );
}