import React, { useEffect, useState } from "react";
import { requestListController } from "../../services/retailerServices/retailerServices";
import { toast } from "react-toastify";
import { dateFormat, dateFormatYear } from "../../utility/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import useDebounce from "../../hooks/useDebounce";
import { permissionChecker } from "../../config/roleAccess";
import { usePermission } from "../../context/create_Context";

function RequestList() {
  const [totalList, setTotalList] = useState();
  const [requestListData, setRequestListData] = useState([]);
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const debounceValue = useDebounce(search, 500);
  const {permission}=usePermission();

  const fetchingRequestList = async () => {
    try {
      showLoader();
      const response = await requestListController(
        activePage ? activePage : "",
        search ? search : "",
        limit ? limit : "",
        filterByDate ? dateFormatYear(filterByDate) : ""
      );
      if (response.code === 200) {
        hideLoader();
        setRequestListData(response?.data?.rows);
        setTotalList(response?.data?.count);
      } else {
        toast.error(response.message);
        hideLoader();
      }
    } catch (e) {
      console.log(e.message);
      hideLoader();
    }
  };

  // Clear Filter
  const clearFilter = () => {
    setFilterByDate("");
    setActivePage(1);
    setLimit("10");
    setSearch("");
    fetchingRequestList();
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    fetchingRequestList();
  }, [limit, activePage, debounceValue, filterByDate]);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2"
                 onClick={() => {
                  navigate(-1);
                }}
                >
                  <i
                    className="mdi mdi-chevron-double-left"
                   
                  ></i>
                </span>
                Retailer Requests{" "}
              </h3>
              <div className="button_wrap">
                <Link
                  to="#"
                  className="btn btn-outline-success cstm_btn_wrap_theme mx-2"
                  onClick={clearFilter}
                >
                  <i className="mdi mdi-refresh menu-icon menu-icon"></i> Reset
                </Link>
              </div>
            </div>
          </div>

          <div className="row wrap_selectCstm">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-3 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search cstm_btn_wrap_theme"
                  placeholder="Search by name,email address or phone number"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-2 cstm_padding_set">
              <div className="datePicker_wrap cstm_date_picker">
                <DatePicker
                  showIcon
                  placeholderText="Start Date"
                  icon={true}
                  className="input date_picker cstm_btn_wrap_theme2 "
                  selected={filterByDate}
                  value={filterByDate}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  onChange={(date) => setFilterByDate(date)}
                  dateFormat="dd/MM/yyyy"
                  maxDate={Date.now()}
                  onChangeRaw={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>
          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Retailer Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>Requested On</th>
                        <th>Status</th>
                        {permissionChecker(permission,2,3) && (
                        <th>Action</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {requestListData.map(
                        (
                          {
                            id,
                            retailerName,
                            email,
                            mobileNumber,
                            updatedAt,
                            status,
                            isApproved
                          },
                          index
                        ) => {
                          return (
                            <React.Fragment key={id}>
                              <tr>
                                <td>{++index}</td>
                                <td>{retailerName ? retailerName : "---"}</td>
                                <td>{email ? email : "---"}</td>
                                <td>{mobileNumber}</td>
                                <td>{dateFormat(updatedAt)}</td>
                                <td>
                                  {/* {status == 1 ? "Verifed" : "Unverified"} */}
                                  {isApproved === 0 ? (
                                      <button className="btn-gradient-warning">
                                        Unverified
                                      </button>
                                    ) : isApproved === 1 ? (
                                      <button className="btn-gradient-success">
                                        Verified
                                      </button>
                                    ) : isApproved === 2 ? (
                                      <button className="btn-gradient-danger">
                                        Rejected
                                      </button>
                                    ) : (
                                      "---"
                                    )}
                                </td>
                                  {permissionChecker(permission,2,3) && (
                                <td>
                                  <div className="action_button_wrap">
                                    <div className="switch_button">
                                      <NavLink className="action_button"
                                        to={`/view-retailer-request-detail/${id}`}
                                      >
                                        <VisibilityIcon className="cstm_eye" color="secondary" />
                                      </NavLink>
                                    </div>
                                  </div>
                                </td>
                                  )}
                              </tr>
                            </React.Fragment>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + requestListData?.length - 1} of{" "}
                        {totalList} entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RequestList;
