import { instance, setJwtToken } from "../../config/axiosInstance";
import { toast } from "react-toastify";


// Creating an Order for a Specific User by Admin
export const createAdminOrderController = async (userId, orderData,createdByAdmin) => {
  try {
    const data = await instance.post(
      `admin/create-order`,
      {
        userId,
        ...orderData,
        createdByAdmin
      },
      setJwtToken()
    );
    
    if (data?.data?.code === 200) {
      return data?.data;
    } else {
     
      return data?.data;
    }
  } catch (e) {
    console.error('Error creating order:', e.message);
    toast.error('Failed to create order. Please try again.', { id: "order" });
    return { success: false };
  }
};

// Adding a product to the cart for a specific user by Admin
export const addToCartController = async (product_id, quantity, user_id, type = 1) => {
  try {
    const response = await instance.post(
      `admin/add-cart`,
      {
        product_id,
        quantity,
        type, // Now accepting type as a parameter, defaults to 1 if not provided
        user_id
      },
      setJwtToken()
      
    );
    console.log("Type received in addToCartController:", type);

    console.log("res", response);
    
    if (response.data?.code === 200) {
      // Success message based on type
      // const successMessage = type === 1 ? 
      //   'Product successfully updated in cart.' : 
      //   'Bundle successfully updated in cart.';
      // toast.success(successMessage, { id: "add-to-cart" });
      return response.data;
    } else {
      // Error message based on type
      const errorMessage = type === 1 ? 
        'Failed to update product in cart.' : 
        'Failed to update bundle in cart.';
      toast.error(response.data?.message || errorMessage, { id: "add-to-cart" });
      return response.data;
    }
  } catch (e) {
    console.error('Error updating cart:', e.message);
    const errorMessage = type === 1 ? 
      'An error occurred while updating the product in the cart.' : 
      'An error occurred while updating the bundle in the cart.';
    toast.error(errorMessage, { id: "add-to-cart" });
    return { success: false };
  }
};

export const deleteCartController = async (userId) => {
  try {
    const response = await instance.post(
      `admin/delete-user-cart`,
      {
        userId
      },
      setJwtToken()
      
    );
    
    if (response.data?.code === 200) {
      return response.data; 
    } else {
      return response.data;
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

// Fetching Product List Api
export const orderListController = async (
  activePage,
  limit,
  search,
  filterByDate,
  orderNo
) => {
  try {
    // console.log(orderNo,"orderNo")
    const params = {};
    params.search = search;
    params.page = activePage ? activePage : "";
    params.limit = limit ? limit : "";
    params.date = filterByDate ? filterByDate : "";
    params.orderNo = orderNo ? orderNo : "";
    const data = await instance.get(`admin/orders-list`, {
      params,
      ...setJwtToken(),
    });
    if (data?.data?.code === 200) {
      return data?.data;
    } else {
      toast.error(data?.response?.data?.message || data?.data?.message, {
        id: "order",
      });

      return data?.data;
    }
  } catch (e) {
    console.log(e.message);
  }
};

// Fetching Order List Detail
export const viewOrderController = async (id) => {
  const data = await instance.get(`admin/order-detail?id=${id}`, setJwtToken());
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message, {
      id: "order",
    });
    return data?.data;
  }
};

// Fetching order change status
export const changeOrderStatusController = async (reqData) => {
  try {
    // Prepare the request data based on what's being updated
    const orderUpdateData = {
      orderId: reqData.orderId,
      ...(reqData.status !== undefined && { status: reqData.status }),
      ...(reqData.txnId !== undefined && { txnId: reqData.txnId }), // Changed from transactionId to txnId
      ...(reqData.trackingId !== undefined && { trackingId: reqData.trackingId })
    };

    const { data } = await instance.post(
      'admin/update-order-status',
      orderUpdateData,
      setJwtToken()
    );

    if (data?.code === 200) {
      return data;
    } else {
      toast.error(data?.message || "Update failed", {
        id: "order",
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong", {
      id: "order",
    });
    return {
      code: 500,
      message: error?.response?.data?.message || "Something went wrong"
    };
  }
};

export const updateOrderRemark = async (reqData) => {
  try {
    const { data } = await instance.post(
      'admin/order-remark',
      reqData,
      setJwtToken()
    );

    if (data?.code === 200) {
      return data;
    } else {
      toast.error(data?.message || "Update failed", {
        id: "order",
      });
      return data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong", {
      id: "order",
    });
    return {
      code: 500,
      message: error?.response?.data?.message || "Something went wrong"
    };
  }
};

// Fetching cancel order status
export const cancelOrderController = async (reqData) => {
  const data = await instance.post(
    `admin/cancel-order`,
    reqData,
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message, {
      id: "order",
    });
    return data?.data;
  }
};

// Fetching Refund Payment API
export const refundPaymentController = async (reqData) => {
  const data = await instance.post(
    `admin/refund-payment`,
    reqData,
    setJwtToken()
  );
  return data;
};
