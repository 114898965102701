import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
import { productListController } from "../../services/productServices/productListingController";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { orderListController } from "../../services/orderServices/orderListController";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import {
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";
import { usePermission } from "../../context/create_Context";

const OrderManagement = () => {
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const [orderId, setOrderId] = useState("");

  const debounceValue = useDebounce(search, 500);
  const orderDebounceValue = useDebounce(orderId, 500);
  const [loader, show, hide] = useFullPageLoader();
  const { permission } = usePermission();

  const fetchingOrderList = async () => {
    show();
    const response = await orderListController(
      activePage ? activePage : "",
      limit ? limit : "",
      search ? search : "",
      filterByDate ? dateFormatYear(filterByDate) : "",
      orderId ? orderId : ""
    );
    // console.log(response?.data?.rows);
    if (response?.code === 200) {
      setTotalList(response?.data?.count);
      setOrderList(response?.data?.rows);

      // console.log(response?.data?.rows);
      hide();
    } else {
      toast.error(response?.message);
      hide();
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  // Clear filter
  const clearFilter = () => {
    setActivePage(1);
    setLimit(10);
    setSearch("");
    setFilterByDate("");
    fetchingOrderList();
    setOrderId("");
  };

  useEffect(() => {
    fetchingOrderList();
  }, [activePage, limit, debounceValue, filterByDate, orderDebounceValue]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title"> Order Management </h3>
              <div className="button_wrap">
                {permissionChecker(permission, 4, 1) && (
                  <Link
                    className="btn btn-outline-success cstm_btn_wrap_theme mx-2"
                    to="/order-management/create"
                  >
                    <i className="mdi mdi-plus menu-icon"></i> Create Order
                  </Link>
                )}
                <Link
                  to="#"
                  className="btn btn-outline-success cstm_btn_wrap_theme mx-2"
                  onClick={clearFilter}
                >
                  <i className="mdi mdi-refresh menu-icon menu-icon"></i> Reset
                </Link>
              </div>
            </div>
          </div>

          {/* Search bar  */}
          <div className="row wrap_selectCstm">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-3 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by Retailer Name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by Order Id"
                  value={orderId}
                  onChange={(e) => {
                    setOrderId(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="col-lg-2 cstm_padding_set">
              <div className="datePicker_wrap cstm_date_picker">
                <DatePicker
                  showIcon
                  placeholderText="Filter By Date"
                  icon={true}
                  className="input date_picker cstm_btn_wrap_theme2"
                  selected={filterByDate}
                  value={filterByDate}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  onChange={(date) => setFilterByDate(date)}
                  dateFormat="dd/MM/yyyy"
                  maxDate={Date.now()}
                  onChangeRaw={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>

          {/* DropDown for approved Status */}

          {loader}
          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Order Id</th>
                          <th>Retailer Name</th>
                          <th>Phone Number</th>
                          <th>No. Of Items</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Status</th>
                          {actionsChecker(permission, 4) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {orderList && orderList.length > 0 ? (
                          orderList.map(
                            (
                              {
                                id,
                                retailerDetails,
                                // deliveryAmount,
                                totalAmount,
                                noOfItem,
                                orderNo,
                                status,
                                createdAt,
                                mobileNumber,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={id}>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{orderNo}</td>
                                    <td>{retailerDetails?.retailerName}</td>
                                    <td>{retailerDetails?.mobileNumber}</td>
                                    <td>{noOfItem}</td>
                                    <td>{totalAmount}</td>
                                    <td>{showDateFormatYear(createdAt)}</td>
                                    <td>
                                      {status === 0 && (
                                        <button className="btn-gradient-warning">
                                          Pending
                                        </button>
                                      )}
                                      {status === 1 && (
                                        <button className="btn-gradient-success">
                                          Accepted
                                        </button>
                                      )}
                                      {status === 2 && (
                                        <button className="btn-gradient-light">
                                          Shipped
                                        </button>
                                      )}
                                      {status === 3 && (
                                        <button className="btn-gradient-dark">
                                          Delivered
                                        </button>
                                      )}
                                      {status === 4 && (
                                        <button className="btn-gradient-danger">
                                          Cancelled
                                        </button>
                                      )}
                                      {status === 5 && (
                                        <button className="btn-gradient-info">
                                          Refunded
                                        </button>
                                      )}
                                      {status !== 0 &&
                                        status !== 1 &&
                                        status !== 2 &&
                                        status !== 3 &&
                                        status !== 4 &&
                                        status !== 5 &&
                                        " "}
                                    </td>

                                    {permissionChecker(permission, 4, 3) && (
                                      <td>
                                        <div className="action_button_wrap">
                                          <Link
                                            to={`/order-management/view/${id}`}
                                            className="action_button"
                                          >
                                            <VisibilityIcon
                                              className="cstm_eye"
                                              color="secondary"
                                            />
                                          </Link>
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}>Orders Not Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + orderList?.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderManagement;
