import { instance, setJwtToken } from "../../config/axiosInstance";

// Fetching Product List Api
export const shortBookListingController = async () => {
  const data = await instance.get(`admin/digital-shotbook-list`, setJwtToken());
  return data;
};

// Fetching Product List Api
export const addProductsCartController = async (reqData) => {
  const data = await instance.post(`admin/add-to-cart`, reqData, setJwtToken());
  return data;
};

// converting digital-cart into original cart
export const repeatCartController = async (reqData) => {
  const data = await instance.post(`admin/repeat-cart`, reqData, setJwtToken());
  return data;
};


// Fetching Product List Review Api
export const ProductsCartListReviewController = async (userId, activePage) => {
  const params = {};
  params.userId = userId ? userId : "";
  params.page = activePage ? activePage : "";
  const data = await instance.get(`admin/cart-list`, {
    params,
    ...setJwtToken(),
  });
  return data;
};

// Fetching Product List Review Api
export const ProductsCartUpdateController = async (reqData) => {
  const data = await instance.post(`admin/update-cart`, reqData, setJwtToken());
  return data;
};
