import React, { useState, useEffect, useRef } from "react";
import { retailerListWithoutPaginationController } from "../../services/retailerServices/retailerServices";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  addToCartController,
  deleteCartController,
} from "../../services/orderServices/orderListController";
import useButtonLoader from "../../hooks/useButtonLoader";
import ModalBox from "../../common/ModalBox";
import { getCartItemsController } from "../../services/recommendedServices/recommendedController";
import { Checkbox, FormControlLabel } from "@mui/material";
import { createAdminOrderController } from "../../services/orderServices/orderListController";
import { productListController } from "../../services/recommendedServices/recommendedController";
import { pointsListingController } from "../../services/pointsManagementServices/pointsManagementController";
import { bundleListController } from "../../services/bundleServices/bundleController";

const CreateOrder = () => {
  const [retailers, setRetailers] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [searchRetailer, setSearchRetailer] = useState("");
  const [calculatedAmount, setCalculatedAmount] = useState({});
  const [cloneProductList, setCloneProductList] = useState([]);
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [search, setSearch] = useState("");
  const [searchBundle, setSearchBundle] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [filteredRetailers, setFilteredRetailers] = useState([]);
  const [retailerSearch, setRetailerSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cartExistsModal, setCartExistsModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [transactionType, setTransactionType] = useState("");
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [transactionId, setTransactionId] = useState("");
  const [codCharge, setCodCharge] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { cartData, userId } = location.state || {};
  const [addProductList, setAddProductList] = useState(cartData || []);
  const [selectedRetailerId, setSelectedRetailerId] = useState(userId || "");
  const [productList, setProductList] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [selectedBundleId, setSelectedBundleId] = useState("");
  const retailerDropdownRef = useRef(null);
  const [clonedBundles, setClonedBundles] = useState(bundles); 
  const [addedBundles, setAddedBundles] = useState([]);

  const fetchRetailers = async (userId) => {
    try {
      const response = await retailerListWithoutPaginationController(userId);
      if (response?.code === 200 && response?.data?.rows) {
        setRetailers(response.data.rows);
        setFilteredRetailers(response.data.rows);
      } else {
        toast.error("Failed to fetch retailers");
      }
    } catch (error) {
      toast.error("Error fetching retailers");
    } finally {
      setSubmitButton(false);
    }
  };

  useEffect(() => {
    if (userId) {
        setSelectedRetailerId(userId);
        setShowModal(true)
        fetchRetailers(userId);
    }
  }, [userId]);

  const fetchCodCharge = async () => {
    try {
      const response = await pointsListingController();
      if (response?.data?.data) {
        const codChargeData = response.data.data.find((item) => item.id === 8);
        if (codChargeData) {
          setCodCharge(parseFloat(codChargeData.amount));
          console.log(codCharge);
        }
      } else {
        toast.error("Failed to fetch COD charges");
      }
    } catch (error) {
      toast.error("Error fetching COD charges");
    }
  };

  const fetchingProductList = async () => {
    try {
      const response = await productListController();
      if (response?.data?.code === 200) {
        const filteredProductList = response?.data?.data.filter(
          ({ noOfStock }) => noOfStock !== 0
        );
        setProductList(filteredProductList);
        setCloneProductList(filteredProductList);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (error) {
      toast.error("Error fetching product list");
    } finally {
      setSubmitButton(false);
    }
  };

  const fetchBundles = async () => {
    try {
      const response = await bundleListController();
    //  console.log("response:", response);
      if (response?.data?.code === 200) {
        setBundles(
          response.data.data.bundle.filter((item) => item.status === 1)
        );
        setClonedBundles(
          response.data.data.bundle.filter((item) => item.status === 1)
        );
      } else {
        toast.error("Failed to fetch bundles");
      }
    } catch (error) {
      toast.error("Error fetching bundles");
    }
  };

  const handleTransactionTypeChange = (e) => {
    const selectedType = e.target.value;
    setTransactionType(selectedType);

    if (selectedType === "COD") {
      // If COD is selected, add the COD charge to the delivery amount
      setDeliveryAmount(codCharge);
    }
    else {
      // Reset delivery amount if another type is selected
      setDeliveryAmount(0);
    }
  };

  useEffect(() => {
    if (retailers.length) {
      setFilteredRetailers(
        retailers.filter((retailer) => {
          const searchQuery = retailerSearch?.toLowerCase();
          return (
            retailer?.retailerName?.toLowerCase().includes(searchQuery) ||
            retailer?.mobileNumber?.toString().includes(searchQuery)
          );
        })
      );
    }
  }, [retailerSearch, retailers]);

// handleSearchProduct function modification
const handleSearchProduct = (e) => {
  const searchValue = e.target.value;
  setSearch(searchValue);
  filterProductList(searchValue);
};

// new function to handle product filtering
const filterProductList = (searchValue) => {
  if (!searchValue.trim()) {
    const updatedProducts = productList.map((product) => {
      const cartItem = cartItems.find((item) => item.productId === product.id);
      return {
        ...product,
        qty: cartItem ? cartItem.quantity : 0,
      };
    });
    setCloneProductList(updatedProducts);
    return;
  }

  const filteredProducts = productList.filter((product) =>
    product.productName?.toLowerCase().includes(searchValue.toLowerCase().trim())
  );

  const updatedFilteredProducts = filteredProducts.map((product) => {
    const cartItem = cartItems.find((item) => item.productId === product.id);
    return {
      ...product,
      qty: cartItem ? cartItem.quantity : 0,
    };
  });

  setCloneProductList(updatedFilteredProducts);
};

const handleSearchBundle = (e) => {
  const searchValue = e.target.value;
  setSearchBundle(searchValue);
  filterBundleList(searchValue);
};

// new function to handle bundle filtering
const filterBundleList = (searchValue) => {
  // console.log("Search Value:", searchValue);
  // console.log("Original Bundles:", bundles);

  if (!searchValue.trim()) {
    const updatedBundles = bundles.map((bundle) => {
      const cartItem = cartItems.find(
        (item) => item.productId === bundle.id && item.type === 2
      );
      return {
        ...bundle,
        qty: cartItem ? cartItem.quantity : 0,
      };
    });
 //   console.log("Unfiltered Updated Bundles:", updatedBundles);
    setClonedBundles(updatedBundles);
    return;
  }

  const filteredBundles = bundles.filter((bundle) => {
    const isMatch = bundle.bundleName?.toLowerCase().includes(searchValue.toLowerCase().trim());
  //  console.log(`Checking bundle: ${bundle.bundleName}, Match: ${isMatch}`);
    return isMatch;
  });
  
  //console.log("Filtered Bundles:", filteredBundles);

  const updatedFilteredBundles = filteredBundles.map((bundle) => {
    const cartItem = cartItems.find(
      (item) => item.productId === bundle.id && item.type === 2
    );
    const updatedBundle = {
      ...bundle,
      qty: cartItem ? cartItem.quantity : 0,
    };
   // console.log(`Updated Bundle: ${JSON.stringify(updatedBundle)}`);
    return updatedBundle;
  });

 // console.log("Updated Filtered Bundles:", updatedFilteredBundles);
  setClonedBundles(updatedFilteredBundles);
};


  const handleAddProductQuantity = async (id) => {
    if (!selectedRetailerId) {
      return toast.error("Please select User first");
    }
  
    const existingProduct = addProductList.find(
      (item) => item.productId === id
    );
    const cartItem = cartItems.find((item) => item.productId === id);
    const product = cloneProductList.find((item) => item.id === id);
  
    if (product.netPrice <= 0) {
      toast.error(
        `${product.productName} cannot be added to cart because net price is 0`
      );
      return;
    }
  
    try {
      if (existingProduct) {
        const updatedList = [...addProductList];
        const index = updatedList.indexOf(existingProduct);
        updatedList[index].qty++;
        setAddProductList(updatedList);
      } else if (cartItem) {
        setAddProductList((prev) => [
          ...prev,
          { productId: id, qty: cartItem.quantity + 1 },
        ]);
      } else {
        const response = await addToCartController(id, 1, selectedRetailerId);
        if (response && response?.code === 200) {
          setCartItems((prevItems) => [
            ...prevItems,
            { productId: id, quantity: 1, productDetails: product },
          ]);
          setAddProductList((prev) => [...prev, { productId: id, qty: 1 }]);
  
          // Update only the selected product's quantity in cloneProductList
          setCloneProductList((prevList) =>
            prevList.map((item) =>
              item.id === id ? { ...item, qty: 1 } : item
            )
          );
          
          setShowModal(true);
        } else {
          console.error("Error adding to cart:", response);
        }
      }
      
      // Fetch cart items after updating
      await fetchCartItems();
      
      // Reapply the search filter after cart update
      if (search.trim()) {
        filterProductList(search);
      }
    } catch (error) {
      console.error("Error in handleAddProductQuantity:", error);
      toast.error("Error adding product to cart");
    }
  };

  // const handleBundleSelect = async (bundleId) => {
  //   setSelectedBundleId(bundleId);
  //   const quantity = 1;
  //   const type = 2;

  //   try {
  //     const response = await addToCartController(
  //       bundleId,
  //       quantity,
  //       selectedRetailerId,
  //       type
  //     );
  //     console.log("Response from adding bundle:", response);

  //     if (response?.code === 200) {
  //       const bundleDetails = bundles.find((bundle) => bundle.id === bundleId);

  //       setCartItems((prevItems) => [
  //         ...prevItems,
  //         {
  //           id: response?.data?.id || bundleId,
  //           type: type,
  //           productId: bundleId,
  //           quantity: quantity,
  //           productDetails: {
  //             bundleName: bundleDetails?.bundleName,
  //             noOfStock: bundleDetails?.noOfStock,
  //             bundlePrice: bundleDetails?.bundlePrice,
  //             image: bundleDetails?.image,
  //           },
  //         },
  //       ]);
  //       console.log("Bundle selected with type:", type);
  //       toast.success("Bundle added to cart");
  //       fetchCartItems();
  //       setShowModal(true);
  //     } else {
  //       toast.error(response?.message || "Failed to add bundle to cart");
  //     }
  //   } catch (error) {
  //     console.error("Error adding bundle to cart:", error);
  //     toast.error("Error adding bundle to cart");
  //   }
  // };

  const handleBundleSelect = (bundleId) => {
    if (!selectedRetailerId) {
      return toast.error("Please select User first");
    }

    const existingBundle = addedBundles.find(
      (item) => item.bundleId === bundleId
    );

    const cartItem = cartItems.find((item) => item.productId === bundleId);
    const bundle = clonedBundles.find((item) => item.id === bundleId);

    if (!bundle || bundle.noOfStock <= 0) {
      toast.error("Bundle is out of stock");
      return;
    }

    // Check if the bundle has a valid net price
    if (bundle.netPrice <= 0) {
      toast.error(
        `${bundle.bundleName} cannot be added to cart because net price is 0`
      );
      return;
    }

    // Increment quantity if the bundle is already in the addedBundles array
    if (existingBundle) {
      setAddedBundles((prev) =>
        prev.map((item) =>
          item.bundleId === bundleId
            ? { ...item, quantity: item.quantity + 1 } // Increment quantity
            : item
        )
      );
      // Optionally, you can also update the cart item quantity here if needed
      if (cartItem) {
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.productId === bundleId
              ? { ...item, quantity: cartItem.quantity + 1 }
              : item
          )
        );
      }
    } else if (cartItem) {
      // If the bundle is already in the cart but not in addedBundles, update addedBundles
      setAddedBundles((prev) => [
        ...prev,
        { bundleId, quantity: cartItem.quantity + 1 },
      ]);
    } else {
      // If the bundle is not in the cart, add it to the cart
      addToCartController(bundleId, 1, selectedRetailerId, 2).then(
        async (response) => {
          if (response && response?.code === 200) {
            setCartItems((prevItems) => [
              ...prevItems,
              { productId: bundleId, quantity: 1, productDetails: bundle },
            ]);
            setAddedBundles((prev) => [...prev, { bundleId, quantity: 1 }]);

            setClonedBundles((prevList) =>
              prevList.map((item) =>
                item.id === bundleId ? { ...item, qty: 1 } : item
              )
            );
            await fetchCartItems();
            setShowModal(true);
          } else {
            console.error("Error adding to cart:", response);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (selectedRetailerId) {
      setAddProductList([]);
      setAddedBundles([]);
      fetchBundles();
    }
  }, [selectedRetailerId]);

  // // Increment Product Quantity Handler
  // const incrementProductQuantityHandler = (id) => {
  //   const index = addProductList.findIndex((item) => item?.productId === id);
  //   if (index !== -1) {
  //     const updatedList = [...addProductList];
  //     updatedList[index].qty++;
  //     setAddProductList(updatedList);
  //     console.log(addProductList);
  //   }
  // };

  // // Decrement Product Quantity Handler
  // const decrementProductQuantityHandler = (id) => {
  //   const index = addProductList.findIndex((item) => item?.productId === id);
  //   if (index !== -1) {
  //     const updatedList = [...addProductList];
  //     if (updatedList[index].qty === 1) {
  //       updatedList.splice(index, 1);
  //     } else {
  //       updatedList[index].qty--;
  //     }
  //     setAddProductList(updatedList);
  //   }
  // };

  // Update product quantity in the clone product list
  const updateProductQuantity = (productId, newQuantity) => {
    setCloneProductList((prevList) =>
      prevList.map((item) =>
        item.id === productId ? { ...item, qty: newQuantity } : item
      )
    );
  };

  // Function to increment quantity
  const incrementCartItemQuantity = async (
    productId,
    currentQuantity,
    itemType
  ) => {
    const newQuantity = currentQuantity + 1;

    // If it's a bundle, you might want to handle this differently if it contains multiple products.
    const response = await addToCartController(
      productId,
      newQuantity,
      selectedRetailerId,
      itemType
    );

    if (response?.code === 200) {
      await fetchCartItems();
      // Update the cartItems and addProductList state
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.productId === productId
            ? { ...item, quantity: newQuantity }
            : item
        )
      );
      setAddProductList((prevList) =>
        prevList.map((item) =>
          item.productId === productId ? { ...item, qty: newQuantity } : item
        )
      );

      // Update the quantity in the clone product list
      updateProductQuantity(productId, newQuantity);
    } else {
      console.error("Error updating cart:", response);
    }
  };

  // Function to decrement quantity
  const decrementCartItemQuantity = async (
    productId,
    currentQuantity,
    itemType
  ) => {
    const newQuantity = currentQuantity - 1;

    if (newQuantity <= 0) {
      // If the new quantity is 0, remove the item from the cart
      const response = await addToCartController(
        productId,
        0,
        selectedRetailerId,
        itemType
      );
      if (response?.code === 200) {
        await fetchCartItems();
        // Update the state to remove the item from the cart
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.productId !== productId)
        );
        setAddProductList((prevList) =>
          prevList.filter((item) => item.productId !== productId)
        );
        // Update the quantity to 0 in the clone product list
        updateProductQuantity(productId, 0);
      } else {
        console.error("Error removing item from cart:", response);
      }
    } else {
      // If the new quantity is greater than 0, update the quantity in the cart
      const response = await addToCartController(
        productId,
        newQuantity,
        selectedRetailerId,
        itemType
      );
      if (response?.code === 200) {
        await fetchCartItems();
        // Update the cartItems and addProductList state
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.productId === productId
              ? { ...item, quantity: newQuantity }
              : item
          )
        );
        setAddProductList((prevList) =>
          prevList.map((item) =>
            item.productId === productId ? { ...item, qty: newQuantity } : item
          )
        );

        // Update the quantity in the clone product list
        updateProductQuantity(productId, newQuantity);
      } else {
        console.error("Error updating cart:", response);
      }
    }
  };

  const handleRemoveProductFromCart = async (productId) => {
    // Set quantity to 0 and remove the product from the cart
    const response = await addToCartController(
      productId,
      0,
      selectedRetailerId
    );
    if (response?.code === 200) {
      // Update the state to remove the product from cartItems and addProductList
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.productId !== productId)
      );
      setAddProductList((prevList) =>
        prevList.filter((item) => item.productId !== productId)
      );
      // Update the cloneProductList to reflect the quantity change
      updateProductQuantity(productId, 0);
      fetchCartItems();
    } else {
      console.error("Error removing item from cart:", response);
    }
  };

  const fetchCartItems = async () => {
    try {
      const response = await getCartItemsController(selectedRetailerId);
      const responseData = response.data;

     // console.log("Fetched Cart Data:", responseData); // Log the raw response data

      if (responseData?.code === 200 && responseData.data.cartData) {
        // Log the cartData to inspect its structure
        const fetchedCartData = responseData.data.cartData;

        const cartData = fetchedCartData.map((item) => {
          // Log each item being processed

          // Use the existing type from the fetched data
          const itemType = item.type;

          // Log the determined type
        //  console.log(`Determined type for item ${item.id}:`, itemType);

          return {
            ...item,
            type: itemType,
          };
        });

        setCartItems(cartData); // Set the updated cart items
        setCalculatedAmount(responseData.data.calculatedAmount);
        return responseData.data;
      } else if (
        responseData?.code === 400 &&
        responseData.message === "Cart is empty."
      ) {
        setCartItems([]);
        setCalculatedAmount({});
        setCartExistsModal(false);
        return null;
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setCartItems([]);
      setCalculatedAmount({});
      return null;
    }
  };

  // const addToCart = async () => {
  //   if (!selectedRetailerId) {
  //     toast.error("Please select a retailer.");
  //     return;
  //   }

  //   try {
  //     const productsToAdd = addProductList.map((item) => ({
  //       productId: item.productId,
  //       qty: item.qty,
  //     }));

  //     const responses = await Promise.all(
  //       productsToAdd.map((product) =>
  //         addToCartController(
  //           product.productId,
  //           product.qty,
  //           selectedRetailerId
  //         )
  //       )
  //     );

  //     if (responses.every((response) => response.code === 200)) {
  //       toast.success("Products added to cart successfully!");
  //     } else {
  //       toast.error("Error adding products to cart");
  //     }
  //   } catch (error) {
  //     toast.error("Error adding products to cart");
  //   }
  // };

  const handleRetailerChange = async (retailerId) => {
    // Clear previous user's data first
    setCartItems([]);
    setCalculatedAmount({});
    setAddProductList([]);
    setAddedBundles([]);
    setSelectedRetailerId(retailerId || userId);
    const selectedRetailer = retailers.find(
      (retailer) => retailer.id === (retailerId || userId)
    );

    // Check if the retailer's profile is completed and verified
    if (!selectedRetailer || selectedRetailer.isProfileCompleted !== 2) {
      toast.error("Retailer's profile is not completed.");
      setSelectedRetailerId("");
      return;
    }
    if (!selectedRetailer || selectedRetailer.isApproved !== 1) {
      toast.error("Retailer's profile is not verified.");
      setSelectedRetailerId("");
      return;
    }

    try {
      const response = await getCartItemsController(retailerId);
      const responseData = response.data;

      if (
        responseData?.code === 200 &&
        responseData.data.cartData &&
        responseData.data.cartData.length > 0
      ) {
        // Only show modal if there are actually items in the cart
        setCartExistsModal(true);
        setCartItems(responseData.data.cartData);
        setCalculatedAmount(responseData.data.calculatedAmount);
      } else {
        setCartExistsModal(false);
        setCartItems([]);
        setCalculatedAmount({});
      }
    } catch (error) {
      console.error("Error checking cart:", error);
      setCartExistsModal(false);
      setCartItems([]);
      setCalculatedAmount({});
    }
  };

  useEffect(() => {
    if (selectedRetailerId) {
      setCartItems([]);
      setAddProductList([]);
      setAddedBundles([]);
      fetchCartItems();
      //  handleRetailerChange(selectedRetailerId);
    }
  }, [selectedRetailerId]);

  const handleCreateOrder = async (event) => {
    event.preventDefault();
    if (!selectedRetailerId) {
      toast.error("Please select a retailer.");
      return;
    }
    if (!transactionType) {
      toast.error("Please select transaction type.");
      return;
    }
    setLoading(true);
    try {
      const cartResponse = await fetchCartItems();
      const cartData = cartResponse.cartData;
      const calculatedAmount = cartResponse.calculatedAmount;

      const totalAmount = calculatedAmount.totalAmount + deliveryAmount;

      const orderPayload = {
        gstAmount: calculatedAmount.gst,
        totalAmount: totalAmount,
        walletDiscount: calculatedAmount.walletDiscount,
        totalItemPrice: calculatedAmount.totalItemPrice,
        deliveryCharge: deliveryAmount,
        transactionType: transactionType,
        txnId: transactionId,
      };

      const response = await createAdminOrderController(
        selectedRetailerId,
        orderPayload,
        1
      );
      if (response?.code === 200) {
        toast.success("Order created successfully!");
        navigate("/order-management");
        setSelectedRetailerId("");
        setCartItems([]);
        setAddProductList([]);
        setAddedBundles([]);
        setCloneProductList([]);
        setSearch("");
        setRetailerSearch("");
        setTransactionType("");
      } else {
        const errorMessage = response?.message || "Failed to create order";
        toast.error(errorMessage);
      }
    } catch (error) {
      const errorMessage = error.response?.message || "Error creating order";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  // const handleCreateNewCart = async () => {
  //   try {
  //     // Create an array of promises based on the cart items
  //     const promises = cartItems.map((item) => {
  //       // Check if the item is a bundle or a product
  //       const quantityToRemove = item.type === 2 ? 0 : item.quantity;
  //       return addToCartController(
  //         item.productId,
  //         quantityToRemove,
  //         selectedRetailerId,
  //         item.type
  //       );
  //     });

  //     const responses = await Promise.all(promises);

  //     // Check if all responses indicate success
  //     if (responses.every((response) => response?.code === 200)) {
  //       // Clear the cart and related states
  //       setCartItems([]);
  //       setAddProductList([]);
  //       setAddedBundles([])
  //       setCalculatedAmount({});
  //       setCartExistsModal(false);
  //       toast.success("Cart items successfully removed");
  //     } else {
  //       toast.error("Failed to delete cart items");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting cart items:", error);
  //     toast.error("Error deleting cart items");
  //   }
  // };
  const handleCreateNewCart = async () => {
    try {
        const response = await deleteCartController(selectedRetailerId);
       // console.log(response)

      if (response?.code === 200) {
        setCartItems([]);
        setAddProductList([]);
        setCalculatedAmount({});
        setCartExistsModal(false);
        toast.success("Cart items successfully removed");
      } else {
        toast.error(response?.message || "Failed to delete cart items");
      }
    } catch (error) {
      console.error("Error deleting cart items:", error);
      toast.error("Error deleting cart items");
    }
  };

  useEffect(() => {
    fetchRetailers();
    fetchCodCharge();
    if (selectedRetailerId) {
      fetchingProductList();
    }
  }, [selectedRetailerId]);

  // const toggleCartVisibility = (e) => {
  //   e.preventDefault();
  //   if (!selectedRetailerId) {
  //     toast.error("Please select a retailer first.");
  //     return;
  //   }
  //   if (!showModal) {
  //     fetchCartItems();
  //   }
  //   setShowModal(!showModal);
  // };

  // useEffect(() => {
  //   if (selectedRetailerId) {
  //     // Create a map of cart items for quick lookup
  //     const cartItemsMap = cartItems.reduce((acc, item) => {
  //       acc[item.productId] = item;
  //       return acc;
  //     }, {});
  
  //     // Update products
  //     // const updatedProductList = search.trim()
  //     //   ? cloneProductList.map(product => ({
  //     //       ...product,
  //     //       qty: cartItemsMap[product.id]?.quantity || 0
  //     //     }))
  //     //   : productList.map(product => ({
  //     //       ...product,
  //     //       qty: cartItemsMap[product.id]?.quantity || 0
  //     //     }));
  //     // setCloneProductList(updatedProductList);
  
  //     // Update bundles
  //     // const updatedBundleList = bundles.map(bundle => {
  //     //   const cartItem = cartItemsMap[bundle.id];
  //     //   return {
  //     //     ...bundle,
  //     //     qty: cartItem && cartItem.type === 2 ? cartItem.quantity : 0
  //     //   };
  //     // });
  //     // setClonedBundles(updatedBundleList);
  //   }
  // }, [selectedRetailerId, cartItems, productList, cloneProductList, bundles, search]);
  

  // const handleBundleSelect = (bundleId) => {
  //   setSelectedBundleId(bundleId);
  //   setBundleQuantities((prev) => ({
  //     ...prev,
  //     [bundleId]: prev[bundleId] ? prev[bundleId] + 1 : 1, // Initialize or increment quantity
  //   }));
  // };

  // Function to handle removing a bundle from selection
  const handleRemoveBundleFromCart = async (bundleId) => {
    try {
      // Set quantity to 0 for the bundle in the cart
      const response = await addToCartController(
        bundleId,
        0,
        selectedRetailerId,
        2
      );
      if (response?.code !== 200) {
        console.error("Error removing bundle from cart:", response);
        return;
      }

      setAddedBundles((prevList) =>
        prevList.filter((item) => item.bundleId !== bundleId)
      );

      setCartItems((prevItems) =>
        prevItems.filter((item) => item.bundleId !== bundleId)
      );

      updateBundleQuantity(bundleId,0)

      // setClonedBundles((prevBundles) =>
      //   prevBundles.map((bundle) =>
      //     bundle.id === bundleId ? { ...bundle, qty: 0 } : bundle
      //   )
      // );
      // // Clear the selected bundle ID if the removed bundle was selected
      // setSelectedBundleId((prevId) => (prevId === bundleId ? null : prevId));

      // Fetch updated cart items if necessary
      fetchCartItems();
    } catch (error) {
      console.error(
        "An error occurred while removing the bundle from the cart:",
        error
      );
    }
  };

  // Function to increment bundle quantity
  const incrementBundleQuantity = async (bundleId) => {
    // Ensure a retailer is selected
    if (!selectedRetailerId) {
      return toast.error("Please select User first");
    }

    try {
      // Get the current quantity from the state
      const currentQuantity = addedBundles.find(item => item.bundleId === bundleId)?.quantity || 0;
  
      // Send request to update cart with the new quantity for this bundle
      const newQuantity = currentQuantity + 1;
      const response = await addToCartController(bundleId, newQuantity, selectedRetailerId, 2); // Assuming type 2 is for bundles
  
      if (response?.code === 200) {
        await fetchCartItems(); // Fetch updated cart items and calculated amounts

        // Update addedBundles state with the new quantity
        setAddedBundles((prevBundles) =>
          prevBundles.map((item) =>
            item.bundleId === bundleId ? { ...item, quantity: newQuantity } : item
          )
        );

        // Update cartItems state for the bundle's quantity
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.productId === bundleId && item.type === 2
              ? { ...item, quantity: newQuantity }
              : item
          )
        );

        // Update the quantity in the cloned product list
        updateBundleQuantity(bundleId, newQuantity);
      }
    } catch (error) {
      console.error("Error updating bundle quantity:", error);
      toast.error(
        "An error occurred while updating the bundle quantity. Please try again."
      );
    }
  };
  
  

  const updateBundleQuantity = (bundleId, newQuantity) => {
    setClonedBundles((prevList) =>
      prevList.map((item) =>
        item.bundleId === bundleId ? { ...item, qty: newQuantity } : item
      )
    );
  };

  const decrementBundleQuantity = async (bundleId) => {
    // Find the current bundle in the cartItems
    const currentBundle = cartItems.find(
      (item) => item.productId === bundleId && item.type === 2
    );

    if (!currentBundle) {
      console.error("Bundle not found in cart.");
      return;
    }

    const newQuantity = currentBundle.quantity - 1;

    if (newQuantity <= 0) {
      // If new quantity is 0, remove the bundle from the cart
      const response = await addToCartController(
        bundleId,
        0,
        selectedRetailerId,
        2 // Assuming 2 indicates a bundle
      );

      if (response?.code === 200) {
        await fetchCartItems();
        // Update state to remove the bundle from cartItems and addedBundles
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.productId !== bundleId)
        );
        setAddedBundles((prevBundles) =>
          prevBundles.filter((item) => item.bundleId !== bundleId)
        );
        setClonedBundles((prevBundles) =>
          prevBundles.map((bundle) =>
              bundle.id === bundleId ? { ...bundle, qty: 0 } : bundle
          )
      );
        // Update the quantity to 0 in the clone product list
        updateBundleQuantity(bundleId, 0);
      } else {
        console.error("Error removing bundle from cart:", response);
      }
    } else {
      // If new quantity is greater than 0, update the quantity in the cart
      const response = await addToCartController(
        bundleId,
        newQuantity,
        selectedRetailerId,
        2
      );

      if (response?.code === 200) {
        await fetchCartItems();
        // Update the cartItems and addedBundles states
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.productId === bundleId && item.type === 2
              ? { ...item, quantity: newQuantity }
              : item
          )
        );
        setAddedBundles((prevBundles) =>
          prevBundles.map((item) =>
            item.bundleId === bundleId
              ? { ...item, quantity: newQuantity }
              : item
          )
        );

        // Update the quantity in the clone product list
        updateBundleQuantity(bundleId, newQuantity);
      } else {
        console.error("Error updating bundle quantity:", response);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Create Order
            </h2>
            <nav aria-label="breadcrumb"></nav>
            {/* <div className="button_wrap">
              <Link
                to="#"
                className="btn btn-outline-info cstm_btn_wrap_theme"
                onClick={() => {
                  setShowModal(true);
                  fetchCartItems();
                }}
              >
                <i className="mdi mdi-plus menu-icon"></i>Show Cart
              </Link>
            </div> */}
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Select Retailer{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <Dropdown
                              className="w-100"
                              autoClose="outside"
                              ref={retailerDropdownRef}
                            >
                              <Dropdown.Toggle
                                id="dropdown-autoclose-outside"
                                className="bg-white text-dark"
                              >
                                {selectedRetailerId && retailers.length > 0
                                  ? (() => {
                                      const selectedRetailer = retailers.find(
                                        (r) => r.id == selectedRetailerId
                                      );
                                      return selectedRetailer
                                        ? `${selectedRetailer.retailerName} (${selectedRetailer.mobileNumber})`
                                        : "Select Retailer";
                                    })()
                                  : "Select Retailer"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="cstm_styling_drop_set">
                                <input
                                  placeholder="Search retailer with name or number"
                                  className="form-control"
                                  value={retailerSearch}
                                  onChange={(e) =>
                                    setRetailerSearch(e.target.value)
                                  }
                                />
                                {filteredRetailers.length > 0 ? (
                                  filteredRetailers.map((retailer) => (
                                    <Dropdown.Item
                                      key={retailer.id}
                                      onClick={() => {
                                        handleRetailerChange(retailer.id);
                                        retailerDropdownRef.current?.click();
                                      }}
                                      className="text-dark"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <span>{retailer.retailerName}</span>
                                        <span>{retailer.mobileNumber}</span>
                                      </div>
                                    </Dropdown.Item>
                                  ))
                                ) : (
                                  <Dropdown.Item>
                                    No Retailers Available
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            <span
                              style={{ color: "red" }}
                              className="error"
                            ></span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mt-3">
                        <div className="form-group row cstm_drop_styling">
                          <label className="col-sm-3 col-form-label">
                            Select Products{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <Dropdown className="w-100">
                              <Dropdown.Toggle
                                id="dropdown-autoclose-outside"
                                className="bg-white text-dark"
                              >
                                Select Products
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="cstm_styling_drop_set">
                                <input
                                  placeholder="Search product"
                                  className="form-control"
                                  value={search}
                                  onChange={handleSearchProduct}
                                />
                                {cloneProductList &&
                                cloneProductList.length > 0 ? (
                                  cloneProductList.map(
                                    ({ productName, id, qty }) => {
                                      const isChecked =
                                        addProductList.some(
                                          (item) => item.productId === id
                                        ) || qty > 0;
                                      return (
                                        <div
                                          className="cstm_flex_style"
                                          key={id}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={isChecked}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    handleAddProductQuantity(
                                                      id
                                                    );
                                                  } else {
                                                    handleRemoveProductFromCart(
                                                      id
                                                    );
                                                    setAddProductList((prev) =>
                                                      prev.filter(
                                                        (item) =>
                                                          item.productId !== id
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                            }
                                          />
                                          <Dropdown.Item href="#">
                                            {productName}
                                          </Dropdown.Item>
                                          <table>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {addProductList.find(
                                                    (item) =>
                                                      item?.productId === id
                                                  ) || qty > 0 ? (
                                                    <div className="action_button_wrap">
                                                      <div className="switch_button">
                                                        <i
                                                          className="mdi mdi-minus-circle"
                                                          onClick={() => {
                                                            decrementCartItemQuantity(
                                                              id,
                                                              qty
                                                            );
                                                          }}
                                                        ></i>
                                                        <p className="cstm_p_count">
                                                          {addProductList.find(
                                                            (item) =>
                                                              item?.productId ===
                                                              id
                                                          )?.qty ||
                                                            qty ||
                                                            0}
                                                        </p>
                                                        <i
                                                          className="mdi mdi-plus-circle"
                                                          onClick={() =>
                                                            incrementCartItemQuantity(
                                                              id,
                                                              qty
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <Link
                                                      to="#"
                                                      onClick={() =>
                                                        handleAddProductQuantity(
                                                          id
                                                        )
                                                      }
                                                    >
                                                      <i className="mdi mdi-plus-circle"></i>
                                                    </Link>
                                                  )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <Dropdown.Item>
                                {!selectedRetailerId ? 'Select retailer first' : "Products not available"}
                                    
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            <span style={{ color: "red" }} className="error">
                              {errors.productIds}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12 mt-3">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Select Bundle <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <Dropdown className="w-100">
                              <Dropdown.Toggle
                                id="dropdown-autoclose-outside"
                                className="bg-white text-dark"
                              >
                                {selectedBundleId
                                  ? bundles.find(
                                      (b) => b.id === selectedBundleId
                                    )?.bundleName
                                  : "Select Bundle"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="cstm_styling_drop_set">
                                <input
                                  placeholder="Search bundle"
                                  className="form-control"
                                  value={searchBundle}
                                  onChange={handleSearchBundle}
                                />
                                {Array.isArray(clonedBundles) &&
                                clonedBundles.length > 0 ? (
                                  clonedBundles.map(
                                    ({ id, bundleName, qty }) => {
                                      const isChecked =
                                        addedBundles.some(
                                          (item) =>
                                            item.bundleId === id &&
                                            item.quantity > 0
                                        ) || qty > 0;
                                      return (
                                        <div
                                          className="cstm_bundle_style"
                                          key={id}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={isChecked}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    handleBundleSelect(id);
                                                  } else {
                                                    handleRemoveBundleFromCart(
                                                      id
                                                    );
                                                    setAddedBundles((prev) =>
                                                      prev.filter(
                                                        (item) =>
                                                          item.bundleId !== id
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                            }
                                            label={
                                              bundleName || "Unnamed Bundle"
                                            }
                                          />
                                          <div className="action_button_wrap">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    {isChecked ? (
                                                      <div className="switch_button">
                                                        <i
                                                          className="mdi mdi-minus-circle"
                                                          onClick={() =>
                                                            decrementBundleQuantity(
                                                              id
                                                            )
                                                          }
                                                        ></i>
                                                        <p className="cstm_p_count_bundle">
                                                          {addedBundles.find(
                                                            (item) =>
                                                              item.bundleId ===
                                                              id
                                                          )?.quantity ||
                                                            qty ||
                                                            0}
                                                        </p>
                                                        <i
                                                          className="mdi mdi-plus-circle"
                                                          onClick={() =>
                                                            incrementBundleQuantity(
                                                              id,
                                                              qty
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    ) : (
                                                      <Link
                                                        to="#"
                                                        onClick={() =>
                                                          handleBundleSelect(id)
                                                        }
                                                      >
                                                        <i className="mdi mdi-plus-circle"></i>
                                                      </Link>
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <Dropdown.Item>
                                    {!selectedRetailerId ? "Select Retailer First" : "Bundles not available"}
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            <span
                              style={{ color: "red" }}
                              className="error"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Cart Content Section with display condition */}
          {showModal && (
            <div className="row mt-4">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h4 className="card-title mb-0">Cart Details</h4>
                      <div className="row mt-4">
                        <div className="col-sm-12 text-center">
                          <select
                            id="transactionType"
                            className="form-select d-inline-block w-auto me-2"
                            value={transactionType}
                            onChange={handleTransactionTypeChange}
                          >
                            <option value="" disabled>
                              Select Transaction Type
                            </option>{" "}
                            {/* Placeholder option */}
                            <option value="COD">COD</option>
                            <option value="PhonePe">PhonePe</option>
                          </select>
                          <input
                            type="text"
                            className="form-control d-inline-block w-auto" // Added styling to match the dropdown
                            placeholder="Transaction ID"
                            value={transactionId} // Create a new state variable for transactionId
                            onChange={(e) => setTransactionId(e.target.value)} // Create a setter function for transactionId
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={
                          loading || !cartItems.length || !selectedRetailerId
                        }
                        onClick={handleCreateOrder}
                      >
                        {loading ? (
                          <i className="mdi mdi-loading mdi-spin"></i>
                        ) : (
                          <span>Create Order</span>
                        )}
                      </button>
                    </div>

                    <div className="cart-content">
                      {cartItems.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Available Stock</th>
                                <th>Quantity</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cartItems.map((item) => {
                                const isBundle = item.type === 2;
                                const itemName = isBundle
                                  ? item.productDetails?.bundleName ||
                                    "Bundle Name Not Available"
                                  : item.productDetails?.productName ||
                                    "Product Name Not Available";
                                const availableStock =
                                  item.productDetails?.noOfStock ||
                                  "Stock Not Available";
                                const imageUrl = isBundle
                                  ? item.productDetails?.image ||
                                    "bundle-placeholder.jpg"
                                  : item.productDetails?.productImage?.[0]
                                      ?.productImage ||
                                    "product-placeholder.jpg";

                                return (
                                  <tr key={item.id}>
                                  <td className="align-middle">{itemName}</td>
                                  <td className="align-middle">
                                      <img
                                          src={imageUrl}
                                          alt={itemName}
                                          style={{
                                              width: "50px",
                                              height: "50px",
                                              objectFit: "cover",
                                          }}
                                      />
                                  </td>
                                  <td className="align-middle">
                                      {availableStock}
                                  </td>
                                  <td className="align-middle text-center">
                                      <div className="quantity-controls">
                                          <div className="switch_button d-flex align-items-center justify-content-center">
                                              <i
                                                  className="mdi mdi-minus-circle me-2 text-danger"
                                                  style={{
                                                      fontSize: "24px",
                                                      cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                      if (isBundle) {
                                                          decrementBundleQuantity(item.productId, item.quantity);
                                                      } else {
                                                          decrementCartItemQuantity(item.productId, item.quantity);
                                                      }
                                                  }}
                                              ></i>
                                              <p
                                                  className="cstm_p_count mb-0"
                                                  style={{
                                                      fontSize: "18px",
                                                      margin: "0 15px",
                                                  }}
                                              >
                                                  {item.quantity}
                                              </p>
                                              <i
                                                  className="mdi mdi-plus-circle ms-2 text-success"
                                                  style={{
                                                      fontSize: "24px",
                                                      cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                      // Call the appropriate increment function based on item type
                                                      if (isBundle) {
                                                          incrementBundleQuantity(item.productId, item.quantity);
                                                      } else {
                                                          incrementCartItemQuantity(item.productId, item.quantity);
                                                      }
                                                  }}
                                              ></i>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <div className="calculated-amount-section mt-4">
                            <div className="row">
                              <div className="col-md-6 offset-md-6">
                                <div className="card bg-light">
                                  <div className="card-body">
                                    <h4 className="card-title">
                                      Order Summary
                                    </h4>
                                    <div className="calculated-amount-details">
                                      <div className="d-flex justify-content-between mb-2">
                                        <strong>Total Item Price:</strong>
                                        <span>
                                          {calculatedAmount?.totalItemPrice?.toFixed(
                                            2
                                          ) || "N/A"}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between mb-2">
                                        <strong>GST:</strong>
                                        <span>
                                          {calculatedAmount?.gst?.toFixed(2) ||
                                            "N/A"}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between mb-2">
                                        <strong>Delivery Amount:</strong>
                                        <span>
                                          {deliveryAmount.toFixed(2) || "N/A"}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between mb-2">
                                        <strong>Wallet Discount:</strong>
                                        <span>
                                          {calculatedAmount?.walletDiscount?.toFixed(
                                            2
                                          ) || "N/A"}
                                        </span>
                                      </div>
                                      <hr />
                                      <div className="d-flex justify-content-between">
                                        <strong>Total Amount:</strong>
                                        <span className="text-primary h5 mb-0">
                                          {(
                                            (calculatedAmount?.totalAmount ||
                                              0) + deliveryAmount
                                          ).toFixed(2) || "N/A"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="text-center py-4">
                          <p className="mb-0">No items in cart</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalBox
        status={cartExistsModal}
        onClose={() => setCartExistsModal(false)}
        title={"Cart Already Existed"}
        modalSize={"cstm_modal_size"}
        centered={true}
        size={"md"}
        customizedFooter={
          <div className="cart-buttons">
            <button
              className="btn btn-primary"
              onClick={() => handleCreateNewCart()}
            >
              Create New Cart
            </button>

            <button
              className="btn btn-primary"
              onClick={() => {
                setCartExistsModal(false);
                setShowModal(true);
                fetchCartItems();
              }}
            >
              Continue with Existing Cart
            </button>
          </div>
        }
      >
        <div className="cart-modal-content">
          <p>We already have existing cart for this retailer.</p>
        </div>
      </ModalBox>
    </React.Fragment>
  );
};

export default CreateOrder;
