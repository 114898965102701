import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
// import { productListController } from "../../services/productServices/productListingController";
import {
  getCartItemsController,
  productListController,
} from "../../services/recommendedServices/recommendedController";
import ModalBox from "../../common/ModalBox";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { orderListController } from "../../services/orderServices/orderListController";
import ToggleStatus from "../../common/ToggleStatus";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import { userListingController } from "../../services/notificationServices/notificationController";
import {
  addProductsCartController,
  repeatCartController,
} from "../../services/digitalShortbookServices/shortbookController";
import useButtonLoader from "../../hooks/useButtonLoader";
import Dropdown from "react-bootstrap/Dropdown";
import { bundleListController } from "../../services/bundleServices/bundleController";

const CreateCart = () => {
  const location = useLocation();
  const cartData = location.state?.cartData || "";
  const [addProductList, setAddProductList] = useState(
    cartData ? cartData : []
  );
  const [productList, setProductList] = useState([]);
  const [productSearch, setProductSearch] = useState("");
  const [bundleSearch, setBundleSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const { UserId, id } = useParams();
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [cloneProductList, setCloneProductList] = useState([]);
  const [existingCartItems, setExistingCartItems] = useState(false);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(cartData ? true : false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [isCartUpdated, setIsCartUpdated] = useState(false);

  const [bundles, setBundles] = useState([]);
  const [clonedBundles, setClonedBundles] = useState(bundles);
  console.log("response:", addProductList);

  const handleCreateOrder = async () => {
    try {
      let productData = addProductList.map((item) => {
        if (item.type === 2 && item.bundleId) {
          return {
            ...item,
            productId: item.bundleId, // Add new key with the same value
            bundleId: undefined, // Remove the old key
          };
        }
        return item;
      });
      productData = productData.map(({ bundleId, ...item }) => item);

      const response = await repeatCartController({
        userId: UserId,
        shortbookId: id,
        data: productData,
      });
      if (response.data.code === 200) {
        toast.success("Cart converted successfully!");
        navigate("/order-management/create", {
          state: {
            userId: UserId,
            data: addProductList,
          },
        });
      } else {
        toast.error(response.data.message || "Failed to convert cart");
      }
    } catch (error) {
      toast.error("Error converting cart");
    }
  };
  const fetchBundles = async () => {
    try {
      const response = await bundleListController();
      if (response?.data?.code === 200) {
        setBundles(
          response.data.data.bundle.filter((item) => item.status === 1)
        );
        setClonedBundles(
          response.data.data.bundle.filter((item) => item.status === 1)
        );
      } else {
        toast.error("Failed to fetch bundles");
      }
    } catch (error) {
      toast.error("Error fetching bundles");
    }
  };

  // Function to fetch existing cart items
  const fetchExistingCartItems = async () => {
    try {
      const response = await getCartItemsController(UserId);
      const responseData = response.data;

      console.log("Fetched Cart Data:", responseData);

      if (responseData?.code === 200 && responseData.data.cartData) {
        const fetchedCartData = responseData.data.cartData;
        setExistingCartItems(fetchedCartData.length > 0);
        return fetchedCartData;
      } else if (
        responseData?.code === 400 &&
        responseData.message === "Cart is empty."
      ) {
        setExistingCartItems(false);
        return null;
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setExistingCartItems(false);
      return null;
    }
  };

  const handleCreateCart = async () => {
    try {
      show();
      const hasExistingItems = await fetchExistingCartItems();

      if (hasExistingItems) {
        setShowCartModal(true);
      } else {
        handleCreateOrder();
      }
    } catch (error) {
      console.error("Error in handleCreateCart:", error);
      toast.error("Error checking cart status");
    } finally {
      hide();
    }
  };
  useEffect(() => {
    fetchExistingCartItems();
  }, [UserId]);

  const handleSearchBundle = (e) => {
    setBundleSearch(e.target.value);
    const filteredBundles = bundles.filter((bundle) =>
      bundle.bundleName
        .toLowerCase()
        .includes(e.target.value.toLowerCase().trim())
    );
    setClonedBundles(filteredBundles);
  };

  const fetchingProductList = async () => {
    try {
      const response = await productListController();
      if (response?.data?.code === 200) {
        console.log("");
        const filteredProductList = response?.data?.data.filter(
          ({ noOfStock }) => noOfStock !== 0
        );
        setProductList(filteredProductList);
        setCloneProductList(filteredProductList);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
      }
    } catch (error) {
      toast.error("Error fetching product list");
    } finally {
      setSubmitButton(false);
    }
  };

  // Cart Product Handler
  const cartProductsHandler = async () => {
    try {
      const reqData = {
        data: addProductList,
        userId: UserId,
        digitalCartId: id,
      };
      setSubmitButton(true);
      const response = await addProductsCartController(reqData);
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        setIsCartUpdated(true);
        setSubmitButton(false);
        // navigate(`/digital-shortbook/create-cart/review-items/${id}/${UserId}`);
        //navigate(`/digital-shortbook`);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  // Handle Cart Status
  const handleAddProductQuantity = (id, type) => {
    const product = cloneProductList.find((item) => item.id === id);
    const bundle = clonedBundles.find((item) => item.id === id);

    let item = {};

    if (type === 1) {
      item = {
        productId: id,
        productName: product?.productName,
        qty: 1,
        type: type,
        noOfStock: product?.noOfStock,
        image: product?.productImage?.[0],
      };

      if (product?.netPrice <= 0) {
        toast.error(
          `${product?.productName} cannot be added to cart because net price is 0`
        );
        return;
      }

      if (product?.noOfStock < item.qty) {
        toast.error(`${product?.productName} has insufficient Quantity`);
        return;
      }
    } else {
      item = {
        bundleId: id,
        bundleName: bundle?.bundleName,
        qty: 1,
        type: type,
        noOfStock: bundle?.noOfStock,
        image: bundle?.bundleImages?.[0],
        // image: product?.productImage?.[0],
      };

      if (bundle?.netPrice <= 0) {
        toast.error(
          `${bundle?.bundleName} cannot be added to cart because net price is 0`
        );
        return;
      }
      if (bundle?.noOfStock < item.qty) {
        toast.error(`${bundle?.bundleName} has insufficient Quantity`);
        return;
      }
    }
    setAddProductList((prev) => [...prev, item]);
    setShowModal(true);
  };

  // Increment Product Quantity Handler
  const incrementProductQuantityHandler = (id, type) => {
    console.log("addProductList", addProductList);
    const index =
      type === 1
        ? addProductList.findIndex((item) => item?.productId === id)
        : addProductList.findIndex((item) => item?.bundleId === id);
    const product = cloneProductList.find((item) => item.id === id);
    const bundle = clonedBundles.find((item) => item.id === id);

    if (index !== -1) {
      const updatedList = [...addProductList];
      if (type === 1) {
        if (updatedList[index].qty + 1 > product.noOfStock) {
          toast.error(`${product.productName} has insufficient Quantity`);
          return;
        } else {
          updatedList[index].qty++;
        }
      } else {
        if (updatedList[index].qty + 1 > bundle.noOfStock) {
          toast.error(`${bundle.bundleName} has insufficient Quantity`);
          return;
        } else {
          updatedList[index].qty++;
        }
      }

      setAddProductList(updatedList);
    }
  };

  // Decrement Product Quantity Handler
  const decrementProductQuantityHandler = (id, type) => {
    // const index = addProductList.findIndex((item) => item?.productId === id);
    const index =
      type === 1
        ? addProductList.findIndex((item) => item?.productId === id)
        : addProductList.findIndex((item) => item?.bundleId === id);

    if (index !== -1) {
      const updatedList = [...addProductList];

      if (updatedList[index].qty === 1) {
        updatedList.splice(index, 1);
      } else {
        updatedList[index].qty--;
      }

      setAddProductList(updatedList);
    }
  };

  const handleSearchProduct = (e) => {
    setProductSearch(e.target.value);
    const filteredProducts = productList.filter((product) =>
      product.productName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setCloneProductList(filteredProducts);
  };

  // console.log(addProductQuantity, "AddProductQuantity");
  // console.log(addProductList, "AddProductList");

  useEffect(() => {
    fetchingProductList();
    fetchBundles();
  }, [filterByDate, limit, activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title">
                <span
                  className="page-title-icon bg-gradient-primary text-white me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="mdi mdi-chevron-double-left"></i>
                </span>
                {cartData ? "Update Digital Cart" : "Create Digital Cart"}
              </h2>
              {/* <div className="button_wrap">
                {addProductList && addProductList.length > 0 && (
                  <button
                    to="#"
                    className="btn btn-outline-info cstm_btn_wrap_theme"
                    onClick={cartProductsHandler}
                    ref={submitButton}
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i>
                    Add Items
                  </button>
                )}
              </div> */}
            </div>
          </div>

          {loader}
          <div className="card">
            <div className="card-body">
              <div className="col-md-12">
                <div className="form-group row cstm_drop_styling">
                  <label className="col-sm-3 col-form-label">
                    Select Products <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9 text-start">
                    <Dropdown className="w-100">
                      <Dropdown.Toggle
                        id="dropdown-autoclose-outside"
                        className="bg-white text-dark"
                      >
                        Select Products
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="cstm_styling_drop_set">
                        <input
                          placeholder="Search product"
                          className="form-control"
                          value={productSearch}
                          onChange={handleSearchProduct}
                        />
                        {cloneProductList && cloneProductList.length > 0 ? (
                          cloneProductList.map(({ productName, id, qty }) => {
                            const isChecked =
                              addProductList.some(
                                (item) => item.productId === id
                              ) || qty > 0;
                            return (
                              <div className="cstm_flex_style" key={id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isChecked}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          handleAddProductQuantity(id, 1);
                                        } else {
                                          setAddProductList((prev) =>
                                            prev.filter(
                                              (item) => item.productId !== id
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  }
                                />
                                <Dropdown.Item href="#">
                                  {productName}
                                </Dropdown.Item>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {addProductList.find(
                                          (item) => item?.productId === id
                                        ) || qty > 0 ? (
                                          <div className="action_button_wrap">
                                            <div className="switch_button">
                                              <i
                                                className="mdi mdi-minus-circle"
                                                onClick={() => {
                                                  decrementProductQuantityHandler(
                                                    id,
                                                    1
                                                  );
                                                }}
                                              ></i>
                                              <p className="cstm_p_count">
                                                {addProductList.find(
                                                  (item) =>
                                                    item?.productId === id
                                                )?.qty ||
                                                  qty ||
                                                  0}
                                              </p>
                                              <i
                                                className="mdi mdi-plus-circle"
                                                onClick={() =>
                                                  incrementProductQuantityHandler(
                                                    id,
                                                    1
                                                  )
                                                }
                                              ></i>
                                            </div>
                                          </div>
                                        ) : (
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              handleAddProductQuantity(id, 1);
                                            }}
                                          >
                                            <i className="mdi mdi-plus-circle"></i>
                                          </Link>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })
                        ) : (
                          <Dropdown.Item>No Products Available</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <span style={{ color: "red" }} className="error">
                      {errors.productIds}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Select Bundle <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9 text-start">
                      <Dropdown className="w-100">
                        <Dropdown.Toggle
                          id="dropdown-autoclose-outside"
                          className="bg-white text-dark"
                        >
                          Select Bundle
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="cstm_styling_drop_set">
                          <input
                            placeholder="Search bundle"
                            className="form-control"
                            value={bundleSearch}
                            onChange={handleSearchBundle}
                          />
                          {Array.isArray(clonedBundles) &&
                          clonedBundles.length > 0 ? (
                            clonedBundles.map(({ id, bundleName, qty }) => {
                              const isChecked =
                                addProductList.some(
                                  (item) => item.bundleId === id && item.qty > 0
                                ) || qty > 0;
                              return (
                                <div className="cstm_bundle_style" key={id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isChecked}
                                        // onChange={(e) => {
                                        //   if (e.target.checked) {
                                        //     handleBundleSelect(id);
                                        //   } else {
                                        //     handleRemoveBundleFromCart(id);

                                        //     setAddedBundles((prev) =>
                                        //       prev.filter(
                                        //         (item) => item.bundleId !== id
                                        //       )
                                        //     );
                                        //   }
                                        // }}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            handleAddProductQuantity(id, 2);
                                          } else {
                                            setAddProductList((prev) =>
                                              prev.filter(
                                                (item) => item.bundleId !== id
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    }
                                    label={bundleName || "Unnamed Bundle"}
                                  />
                                  <div className="action_button_wrap">
                                    <table>
                                      <tbody>
                                        <tr>
                                          <td>
                                            {isChecked ? (
                                              <div className="switch_button">
                                                <i
                                                  className="mdi mdi-minus-circle"
                                                  onClick={() =>
                                                    // decrementBundleQuantity(id)
                                                    decrementProductQuantityHandler(
                                                      id,
                                                      2
                                                      // item.quantity,
                                                      // item.type
                                                    )
                                                  }
                                                ></i>
                                                <p className="cstm_p_count_bundle">
                                                  {addProductList.find(
                                                    (item) =>
                                                      item.bundleId === id
                                                  )?.qty ||
                                                    qty ||
                                                    0}
                                                </p>
                                                <i
                                                  className="mdi mdi-plus-circle"
                                                  onClick={() =>
                                                    incrementProductQuantityHandler(
                                                      id,
                                                      2
                                                    )
                                                  }
                                                ></i>
                                              </div>
                                            ) : (
                                              <Link
                                                to="#"
                                                onClick={() =>
                                                  // handleBundleSelect(id)
                                                  handleAddProductQuantity(
                                                    id,
                                                    2
                                                  )
                                                }
                                              >
                                                <i className="mdi mdi-plus-circle"></i>
                                              </Link>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <Dropdown.Item>No Bundles Available</Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                      <span style={{ color: "red" }} className="error"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Cart Content Section with display condition */}
          {showModal && addProductList?.length > 0 && (
            <div className="row mt-4">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h4 className="card-title mb-0">Digital Cart Details</h4>
                      <div className="button_wrap">
                        {(cartData.length > 0 || isCartUpdated) && (
                          <button
                            className="btn btn-outline-info cstm_btn_wrap_theme"
                            onClick={handleCreateCart}
                            disabled={loader}
                          >
                            {loader
                              ? "Creating Order..."
                              : "Convert into Order"}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="cart-content">
                      {addProductList.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Available Stock</th>
                                <th>Quantity</th>
                              </tr>
                            </thead>
                            <tbody>
                              {addProductList.map((item) => {
                                let image = item?.image?.productImage;
                                const isBundle = item.type === 2;
                                const itemName = isBundle
                                  ? item?.bundleName ||
                                    "Bundle Name Not Available"
                                  : item?.productName ||
                                    "Product Name Not Available";
                                const availableStock =
                                  item?.noOfStock || "Stock Not Available";
                                const imageUrl = isBundle
                                  ? item.image?.bundleImage ||
                                    "bundle-placeholder.jpg"
                                  : item.image?.productImage ||
                                    "product-placeholder.jpg";
                                return (
                                  <tr key={item.id}>
                                    <td className="align-middle">{itemName}</td>
                                    <td className="align-middle">
                                      <img
                                        src={imageUrl}
                                        alt={itemName}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </td>
                                    <td className="align-middle">
                                      {availableStock}
                                    </td>
                                    <td className="align-middle text-center">
                                      <div>
                                        <div className="switch_button d-flex align-items-center justify-content-center">
                                          <i
                                            className="mdi mdi-minus-circle me-2 text-danger"
                                            style={{
                                              fontSize: "24px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              item?.type === 1
                                                ? decrementProductQuantityHandler(
                                                    item.productId,
                                                    1
                                                  )
                                                : decrementProductQuantityHandler(
                                                    item.bundleId,
                                                    2
                                                  )
                                            }
                                          ></i>
                                          <p
                                            className="cstm_p_count mb-0"
                                            style={{
                                              fontSize: "18px",
                                              margin: "0 15px",
                                            }}
                                          >
                                            {item.qty}
                                          </p>
                                          <i
                                            className="mdi mdi-plus-circle ms-2 text-success"
                                            style={{
                                              fontSize: "24px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              item?.type === 1
                                                ? incrementProductQuantityHandler(
                                                    item.productId,
                                                    1
                                                  )
                                                : incrementProductQuantityHandler(
                                                    item.bundleId,
                                                    2
                                                  )
                                            }
                                          ></i>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div className="d-flex justify-content-center mt-4">
                            {addProductList && addProductList.length > 0 && (
                              <button
                                to="#"
                                className="btn btn-outline-info cstm_btn_wrap_theme"
                                onClick={cartProductsHandler}
                                ref={submitButton}
                              >
                                <i className="mdi mdi-plus menu-icon menu-icon"></i>
                                {cartData ? "Confirm Updated Cart" : "Confirm Cart"}
                              </button>
                            )}
                          </div>

                          {/* <div className="calculated-amount-section mt-4">
                            <div className="row">
                              <div className="col-md-6 offset-md-6">
                                <div className="card bg-light">
                                  <div className="card-body">
                                    <h4 className="card-title">
                                      Order Summary
                                    </h4>
                                    <div className="calculated-amount-details">
                                      <div className="d-flex justify-content-between mb-2">
                                        <strong>Total Item Price:</strong>
                                        <span>
                                          {calculatedAmount?.totalItemPrice?.toFixed(
                                            2
                                          ) || "N/A"}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between mb-2">
                                        <strong>GST:</strong>
                                        <span>
                                          {calculatedAmount?.gst?.toFixed(2) ||
                                            "N/A"}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between mb-2">
                                        <strong>Delivery Amount:</strong>
                                        <span>
                                          {deliveryAmount.toFixed(2) || "N/A"}
                                        </span>
                                      </div>
                                      <div className="d-flex justify-content-between mb-2">
                                        <strong>Wallet Discount:</strong>
                                        <span>
                                          {calculatedAmount?.walletDiscount?.toFixed(
                                            2
                                          ) || "N/A"}
                                        </span>
                                      </div>
                                      <hr />
                                      <div className="d-flex justify-content-between">
                                        <strong>Total Amount:</strong>
                                        <span className="text-primary h5 mb-0">
                                          {(
                                            (calculatedAmount?.totalAmount ||
                                              0) + deliveryAmount
                                          ).toFixed(2) || "N/A"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      ) : (
                        <div className="text-center py-4">
                          <p className="mb-0">No items in cart</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalBox
        status={showCartModal}
        onClose={() => setShowCartModal(false)}
        customizedFooter={
          <div className="cart-buttons">
            <button
              className="btn btn-primary"
              onClick={() => {
                handleCreateOrder();
                setShowCartModal(false);
              }}
            >
              Convert into Order
            </button>
            <button
              className="btn btn-outline-primary"
              onClick={() => setShowCartModal(false)}
            >
              Back
            </button>
          </div>
        }
      >
        <div className="cart-modal-content">
          <p>
            We already have an existing cart items in original cart for this
            retailer. What would you like to do?
          </p>
        </div>
      </ModalBox>
    </React.Fragment>
  );
};

export default CreateCart;
