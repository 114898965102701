import { instance, setJwtToken } from "../../config/axiosInstance";

// Fetching Recommended Product List Api
export const recommendedProductListController = async () => {
  const data = await instance.get(
    `admin/rocommanded-products-list`,
    setJwtToken()
  );
  return data;
};

export const retailerListController = async () => {
  const data = await instance.get(
    `admin/retailer-list`,
    setJwtToken()
  );
  return data;
};

// Fetch Cart Items API
export const getCartItemsController = async (userId) => {
  try {
    const data = await instance.get(`admin/get-cart`, {
      params: { userId: userId },
      ...setJwtToken()
    });
    return data;
  } catch (error) {
    console.error("Error fetching cart items:", error);
    throw error;
  }
};

// Fetching Product List Api
export const productListController = async () => {
  const data = await instance.get(`admin/products-list`, setJwtToken());
  return data;
};

// admin/add-recommanded-product
export const addRecommendedProductController = async (reqData) => {
    const data = await instance.post(`admin/add-recommanded-product`,{productId:reqData}, setJwtToken());
    return data;
  };

  // Fetching Product List Api
export const changePointStatusController = async (id) => {
  const  data = await instance.post(`admin/update-setting-status`,{id:id}, setJwtToken());
  return data;
};

  // Delete Training Module
  export const deleteRecommendedController = async (id) => {
    const data = await instance.delete(`admin/delete-recommanded/${id}`,setJwtToken());
    return data;
  };

  // Update COD Status API
export const updateCodController = async (cod) => {
  try {
    const data = await instance.post(
      `admin/update-cod-charges`,
      { amount: cod }, 
      setJwtToken()
    );
    return data;
  } catch (error) {
    console.error("Error updating COD status:", error);
    throw error;
  }
};

// Update Minimum Order Threshold API
export const updateMinimumOrderThresholdController = async (threshold) => {
  try {
    const data = await instance.post(
      `admin/minimum-order-threshold`,
      { amount: threshold },
            setJwtToken()
    );
    return data;
  } catch (error) {
    console.error("Error updating minimum order threshold:", error);
    throw error;
  }
};

// Update Inventory API
export const updateInventoryController = async () => {
  try {
    const data = await instance.post(
      `admin/update-inventory`,
      {},
      setJwtToken()
    );
    return data;
  } catch (error) {
    console.error("Error updating inventory:", error);
    throw error;
  }
};