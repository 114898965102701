import React from "react";
import Dashboard from "../pages/dashboard/Dashboard";
import RetailerManagement from "../pages/retailerManagement/RetailerManagement";
import HomeConfiguration from "../pages/homeConfiguration/HomeConfiguration";
import BannerManagement from "../pages/bannerManagement/BannerManagement";
import AddRetailer from "../pages/retailerManagement/AddRetailer";
import EditRetailer from "../pages/retailerManagement/EditRetailer";
import ViewRetailer from "../pages/retailerManagement/ViewRetailer";
import RequestList from "../pages/retailerManagement/RequestList";
import ViewRetailerRequestDetail from "../pages/retailerManagement/ViewRetailerRequestDetail";
import FilterCategoryManagement from "../pages/filterCategoryManagement/FilterCategoryManagement";
import ViewFilterCategoryManagement from "../pages/filterCategoryManagement/ViewFilterCategoryManagement";
import ProductManagement from "../pages/productManagement/ProductManagement";
import OrdersManagement from "../pages/ordersManagement/OrdersManagement";
import ViewOrder from "../pages/ordersManagement/ViewOrder";
import AddProductManagement from "../pages/productManagement/AddProduct";
import AddProduct from "../pages/productManagement/AddProduct";
import EditProduct from "../pages/productManagement/EditProduct";
import HelpAndSupport from "../pages/helpAndSupportManagement/HelpAndSupport";
import PointsManagement from "../pages/pointManagement/PointsManagement";
import OffersManagement from "../pages/offersManagement/OffersManagement";
import AddNewOffers from "../pages/offersManagement/AddNewOffers";
import ViewOffer from "../pages/offersManagement/ViewOffer";
import EditOffer from "../pages/offersManagement/EditOffer";
import CodManagement from "../pages/codManagement/CodManagement";
import QuickMenus from "../pages/homeConfiguration/QuickMenus";
import DeliveryManagement from "../pages/deliveryManagement/DeliveryManagement";
import WalletManagement from "../pages/walletManagement/WalletManagement";
import ViewTransaction from "../pages/walletManagement/ViewTransaction";
import RecommendedManagement from "../pages/recommendedManagement/RecommendationManagement";
import RecommendationManagement from "../pages/recommendedManagement/RecommendationManagement";
import NotificationManagement from "../pages/notificationManagement/NotificationManagement";
import AddNotification from "../pages/notificationManagement/AddNotification";
import ViewProduct from "../pages/productManagement/ViewProduct";
import ProductCategoryManagement from "../pages/productCategoryManagement/ProductCategoryManagement";
import DigitalShortbook from "../pages/digitalShortbook/DigitalShortbook";
import CreateCart from "../pages/digitalShortbook/CreateCart";
import SubAdminManagement from "../pages/subAdminManagement/SubAdminManagement";
import AddRole from "../pages/subAdminManagement/AddRole";
import AddMember from "../pages/subAdminManagement/AddMember";
import ReviewCartList from "../pages/digitalShortbook/ReviewCartList";
import TrainingModule from "../pages/trainingModule/TrainingModule";
import VendorManagement from "../pages/vendorManagement/VendorManagement";
import AddVendor from "../pages/vendorManagement/AddVendor";
import ViewVendorDetail from "../pages/vendorManagement/ViewVendorDetail";
import EditVendor from "../pages/vendorManagement/EditVendor";
import GiftManagement from "../pages/giftManagement/GiftManagement";
import AddGiftProduct from "../pages/giftManagement/AddGiftProduct";
import EditGiftProduct from "../pages/giftManagement/EditGiftProduct";
import SchemeManagement from "../pages/schemeManagement/SchemeManagement";
import AddScheme from "../pages/schemeManagement/AddScheme";
import ViewScheme from "../pages/schemeManagement/ViewScheme";
import EditScheme from "../pages/schemeManagement/EditScheme";
import BundleManagement from "../pages/bundleManagement/BundleManagement";
import AddBundle from "../pages/bundleManagement/AddBundle";
import EditBundle from "../pages/bundleManagement/EditBundle";
import OrderRequestManagement from "../pages/orderRequestManagement/OrderRequestManagement";
import EditMember from "../pages/subAdminManagement/EditMember";
import ViewMember from "../pages/subAdminManagement/ViewMember";
import CMSManagement from "../pages/CmsManagement/CMSManagement";
import { permissionChecker } from "../config/roleAccess";
import Cat1 from "../pages/homeConfiguration/Cat1";
import Cat2 from "../pages/homeConfiguration/Cat2";
import Cat3 from "../pages/homeConfiguration/Cat3";
import Banner from "../pages/homeConfiguration/Banner";
import Spin from "../pages/homeConfiguration/Spin";
import ReferEarn from "../pages/homeConfiguration/ReferEarn";
import Schemes from "../pages/homeConfiguration/Schemes";
import DeleteRetailerList from "../pages/retailerManagement/DeleteRetailerList";
import InventoryManagement from "../pages/inventoryManagement/InventoryManagement";
import HelpAndSupportBackup from "../pages/helpAndSupportManagement/HelpAndSupportBackup";
import GameManagement from "../pages/gameManagement/GameManagement";
import GameRule from "../pages/gameManagement/GameRule";
import ViewBundle from "../pages/bundleManagement/ViewBundle";
import CreateOrder from "../pages/ordersManagement/CreateOrder";

const navPermissionChecker = (permission = [], moduleId) => {
  const role = localStorage.getItem("role");
  const findRoles = permission.find(
    (data) => data?.moduleId && data?.moduleId === moduleId
  );

  if (role === "admin" || (findRoles && findRoles?.moduleId === moduleId)) {
    return true;
  }

  return false;
};

const sidebar = (permission) => {
  return [
    {
      enabled: true,
      name: "Dashboard",
      icon: "mdi mdi-home menu-icon",
      path: "/dashboard",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 2),
      name: "Retailer Management",
      icon: "mdi mdi-account-multiple",
      path: "/retailer-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 3),
      name: "Product Management",
      icon: "mdi mdi-wallet-giftcard",
      path: "/product-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 9),
      name: "Product Category",
      icon: "mdi mdi-database",
      path: "/product-category-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 4),
      name: "Order Management",
      icon: "mdi mdi-cart",
      path: "/order-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 21),
      name: "Order Support Requests",
      icon: "mdi mdi-box-cutter",
      path: "/order-request-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 10),
      name: "Supplier Management",
      icon: "mdi mdi-account-box",
      path: "/vendor-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 5),
      name: "Wallet Points",
      icon: "mdi mdi-coin",
      path: "/points-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 6),
      name: "Offers Management",
      icon: "mdi mdi-auto-fix",
      path: "/offers-management",
      isChild: null,
    },
    {
      enabled: localStorage.getItem("role") === "admin",
      name: "Sub-Admin Management",
      icon: "mdi mdi-account-multiple",
      path: "/sub-admin-management",
      isChild: null,
    },
    // {
    //   enabled: navPermissionChecker(permission, 7),
    //   name: "Delivery Management",
    //   icon: "mdi mdi-truck-delivery",
    //   path: "/delivery-management",
    //   isChild: null,
    // },
    {
      enabled: navPermissionChecker(permission, 8),
      name: "Wallet Transaction",
      icon: "mdi mdi-wallet",
      path: "/wallet-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 11),
      name: "Training Module",
      icon: "mdi mdi-target",
      path: "/training-module",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 22),
      name: "Game Management",
      icon: "mdi mdi-gamepad-variant",
      path: "/game-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 12),
      name: "Digital Shortbook",
      icon: "mdi mdi-book",
      path: "/digital-shortbook",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 13),
      name: "Filter Category",
      icon: "mdi mdi-filter",
      path: "/filter-category-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 14),
      name: "Recommended Products",
      icon: "mdi mdi-white-balance-incandescent",
      path: "/recommendation",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 15),
      name: "Notification Management",
      icon: "mdi mdi-volume-medium",
      path: "/notification-management",
      isChild: null,
    },
    {
      enabled: localStorage.getItem("role") === "admin",
      name: "Home Configuration",
      icon: "mdi mdi-home",
      path: "/home-configuration",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 16),
      name: "Scheme Management",
      icon: "mdi mdi-format-strikethrough-variant",
      path: "/scheme-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 17),
      name: "Gift Management",
      icon: "mdi mdi-gift",
      path: "/gift-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 18),
      name: "Bundle Management",
      icon: "mdi mdi-home",
      path: "/bundle-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 24),
      name: "COD Management",
      icon: "mdi mdi-cash",
      path: "/cod-management",
      isChild: null,
    },
    {
      enabled: localStorage.getItem("role") === "admin",
      name: "CMS Management",
      icon: "mdi mdi-format-text",
      path: "/cms-management",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 23),
      name: "Help And Support",
      icon: "mdi mdi-human-greeting",
      path: "/help-support",
      isChild: null,
    },
    {
      enabled: navPermissionChecker(permission, 19),
      name: "Banner Management",
      icon: "mdi mdi-image",
      path: "/banner-management",
      isChild: null,
    },
  ].filter((side) => side.enabled);
};
export default sidebar;

export const routes = (permission) => {
  return [
    {
      enabled: true,
      element: <Dashboard />,
      path: "/dashboard",
    },
    {
      enabled: navPermissionChecker(permission, 2),
      element: <RetailerManagement />,
      path: "/retailer-management",
    },
    {
      enabled: permissionChecker(permission, 2, 1),
      element: <AddRetailer />,
      path: "/add-retailer",
    },
    {
      enabled: permissionChecker(permission, 2, 4),
      enabled: true,
      element: <DeleteRetailerList />,
      path: "/retailer-management/delete-retailer-detail",
    },

    {
      enabled: permissionChecker(permission, 2, 3),
      element: <ViewRetailer />,
      path: "/view-retailer/:id",
    },
    {
      enabled: permissionChecker(permission, 2, 2),
      element: <EditRetailer />,
      path: "/edit-retailer/:id",
    },
    {
      enabled: permissionChecker(permission, 2, 6),
      element: <RequestList />,
      path: "/retailer-requests-list",
    },
    {
      enabled: true,
      element: <ViewRetailerRequestDetail />,
      path: "/view-retailer-request-detail/:id",
    },
    {
      enabled: navPermissionChecker(permission, 3),
      element: <ProductManagement />,
      path: "/product-management",
    },
    {
      enabled: permissionChecker(permission, 3, 1),
      element: <AddProduct />,
      path: "/product-management/add",
    },
    {
      enabled: permissionChecker(permission, 3, 2),
      element: <EditProduct />,
      path: "/product-management/edit/:productId",
    },
    {
      enabled: permissionChecker(permission, 3, 3),
      element: <ViewProduct />,
      path: "/product-management/view/:productId",
    },
    {
      enabled: navPermissionChecker(permission, 9),
      element: <ProductCategoryManagement />,
      path: "/product-category-management",
    },
    {
      enabled: navPermissionChecker(permission, 4),
      element: <OrdersManagement />,
      path: "/order-management",
    },
    {
      enabled: navPermissionChecker(permission, 4, 1),
      element: <CreateOrder />,
      path: "/order-management/create",
    },
    {
      enabled: permissionChecker(permission, 4, 3),
      element: <ViewOrder />,
      path: "/order-management/view/:id",
    },
    {
      enabled: permissionChecker(permission, 21, 3),
      element: <OrderRequestManagement />,
      path: "/order-request-management",
    },
    {
      enabled: navPermissionChecker(permission, 7),
      element: <DeliveryManagement />,
      path: "/delivery-management",
    },
    // {
    //   enabled: navPermissionChecker(permission, 7),
    //   element: <InventoryManagement />,
    //   path: "/inventory-management",
    // },
    {
      enabled: navPermissionChecker(permission, 10),
      element: <VendorManagement />,
      path: "/vendor-management",
    },
    {
      enabled: permissionChecker(permission, 10, 1),
      element: <AddVendor />,
      path: "/vendor-management/add",
    },
    {
      enabled: permissionChecker(permission, 10, 3),
      element: <ViewVendorDetail />,
      path: "/vendor-management/view/:id",
    },
    {
      enabled: permissionChecker(permission, 10, 2),
      element: <EditVendor />,
      path: "/vendor-management/edit/:id",
    },
    {
      enabled: navPermissionChecker(permission, 14),
      element: <RecommendationManagement />,
      path: "/recommendation",
    },
    {
      enabled: navPermissionChecker(permission, 15),
      element: <NotificationManagement />,
      path: "/notification-management",
    },
    {
      enabled: permissionChecker(permission, 15, 1),
      element: <AddNotification />,
      path: "/notification-management/add-notification",
    },
    {
      enabled: navPermissionChecker(permission, 13),
      element: <FilterCategoryManagement />,
      path: "/filter-category-management",
    },
    {
      enabled: navPermissionChecker(permission, 5),
      element: <PointsManagement />,
      path: "/points-management",
    },
    {
      enabled: navPermissionChecker(permission, 6),
      element: <OffersManagement />,
      path: "/offers-management",
    },
    {
      enabled: permissionChecker(permission, 6, 1),
      element: <AddNewOffers />,
      path: "/offers-management/add-new-offers",
    },
    {
      enabled: permissionChecker(permission, 6, 2),
      element: <EditOffer />,
      path: "/offers-management/edit-offer/:id",
    },
    {
      enabled: permissionChecker(permission, 6, 3),
      element: <ViewOffer />,
      path: "/offers-management/view-offer/:id",
    },
    {
      enabled: permissionChecker(permission, 13, 3),
      element: <ViewFilterCategoryManagement />,
      path: "/filter-category-management/view-filter-category-management/:id",
    },
    {
      enabled: localStorage.getItem("role") === "admin",
      element:
        localStorage.getItem("role") === "admin" ? (
          <SubAdminManagement />
        ) : null,
      path: "/sub-admin-management",
    },
    {
      enabled: true,
      element: <EditMember />,
      path: "/sub-admin-management/edit/:id",
    },
    {
      enabled: true,
      element: <ViewMember />,
      path: "/sub-admin-management/view/:id",
    },
    {
      enabled: true,
      element: <AddMember />,
      path: "/sub-admin-management/add-member",
    },
    {
      enabled: localStorage.getItem("role") === "admin",
      element:
        localStorage.getItem("role") === "admin" ? <HomeConfiguration /> : null,
      path: "/home-configuration",
    },
    {
      enabled: navPermissionChecker(permission, 16),
      element: <SchemeManagement />,
      path: "/scheme-management",
    },
    {
      enabled: permissionChecker(permission, 16, 1),
      element: <AddScheme />,
      path: "/scheme-management/add",
    },
    {
      enabled: permissionChecker(permission, 16, 3),
      element: <ViewScheme />,
      path: "/scheme-management/view/:id",
    },
    {
      enabled: permissionChecker(permission, 16, 2),
      element: <EditScheme />,
      path: "/scheme-management/edit/:id",
    },
    {
      enabled: navPermissionChecker(permission, 17),
      element: <GiftManagement />,
      path: "/gift-management",
    },
    {
      enabled: permissionChecker(permission, 17, 1),
      element: <AddGiftProduct />,
      path: "/gift-management/add",
    },
    {
      enabled: permissionChecker(permission, 17, 2),
      element: <EditGiftProduct />,
      path: "/gift-management/edit",
    },
    {
      enabled: navPermissionChecker(permission, 18),
      element: <BundleManagement />,
      path: "/bundle-management",
    },
    {
      enabled: navPermissionChecker(permission, 24),
      element: <CodManagement/>,
      path: "/cod-management",
    },
    {
      enabled: permissionChecker(permission, 18, 1),
      element: <AddBundle />,
      path: "/bundle-management/add",
    },
    {
      enabled: permissionChecker(permission, 18, 2),
      element: <EditBundle />,
      path: "/bundle-management/edit/:id",
    },
    {
      enabled: permissionChecker(permission, 18, 3),
      element: <ViewBundle />,
      path: "/bundle-management/view/:id",
    },
    {
      enabled: navPermissionChecker(permission, 8),
      element: <WalletManagement />,
      path: "/wallet-management",
    },
    {
      enabled: permissionChecker(permission, 8, 3),
      element: <ViewTransaction />,
      path: "/wallet-management/view-transaction/:id",
    },
    {
      enabled: navPermissionChecker(permission, 11),
      element: <TrainingModule />,
      path: "/training-module",
    },
    {
      enabled: navPermissionChecker(permission, 12),
      element: <DigitalShortbook />,
      path: "/digital-shortbook",
    },
    {
      enabled: permissionChecker(permission, 12, 3),
      element: <CreateCart />,
      path: "/digital-shortbook/create-cart/:UserId/:id",
    },
    {
      enabled: true,
      element: <ReviewCartList />,
      path: "/digital-shortbook/create-cart/review-items/:reviewId/:UserId",
    },
    {
      enabled: localStorage.getItem("role") === "admin",
      element:
        localStorage.getItem("role") === "admin" ? <CMSManagement /> : null,
      path: "/cms-management",
    },

    {
      enabled: true,
      element: <QuickMenus />,
      path: "/home-configuration/5/:id/:categoryId",
    },
    {
      enabled: true,
      element: <Cat1 />,
      path: "/home-configuration/1/:id/:categoryId",
    },
    {
      enabled: true,
      element: <Cat2 />,
      path: "/home-configuration/3/:id/:categoryId",
    },
    {
      enabled: true,
      element: <Cat3 />,
      path: "/home-configuration/9/:id/:categoryId",
    },
    {
      enabled: true,
      element: <Banner />,
      path: "/home-configuration/4/:id/:categoryId",
    },
    {
      enabled: true,
      element: <Spin />,
      path: "/home-configuration/7/:id/:categoryId",
    },
    {
      enabled: true,
      element: <ReferEarn />,
      path: "/home-configuration/6/:id/:categoryId",
    },
    {
      enabled: true,
      element: <Schemes />,
      path: "/home-configuration/8/:id/:categoryId",
    },
    {
      enabled: navPermissionChecker(permission, 23),
      element: <HelpAndSupport />,
      path: "/help-support",
    },

    {
      enabled: navPermissionChecker(permission, 19),
      element: <BannerManagement />,
      path: "/banner-management",
    },
    {
      enabled: navPermissionChecker(permission, 22),
      element: <GameManagement />,
      path: "/game-management",
    },
    {
      enabled: navPermissionChecker(permission, 22),
      element: <GameRule />,
      path: "/game-management/add-rule",
    },
  ].filter((route) => route.enabled);
};
